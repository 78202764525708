<template>
  <v-container
    :tabindex="focusTop"
    style="max-width: 1200px !important"
    :class="showConfirmPage ? 'visible' : 'invisible'"
  >
    <v-form v-model="valid" ref="form" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-md-12 d-none">
          <v-timeline direction="horizontal" line-inset="12">
            <v-timeline-item dot-color="success">
              <template v-slot:icon>
                <v-icon color="white">mdi-magnify</v-icon>
              </template>
            </v-timeline-item>

            <v-timeline-item dot-color="success">
              <template v-slot:icon>
                <v-icon color="white">mdi-airplane</v-icon>
              </template>
            </v-timeline-item>

            <v-timeline-item dot-color="success">
              <template v-slot:icon>
                <v-icon color="white">mdi-account</v-icon>
              </template>
            </v-timeline-item>

            <v-timeline-item dot-color="primary">
              <template v-slot:icon>
                <v-icon color="white" size="20">mdi-cash-multiple</v-icon>
              </template>
            </v-timeline-item>
          </v-timeline>
        </div>

        <div class="col-lg-9 col-md-12 col-sm-12 col-12 mx-0 px-0">
          <div class="px-2">
            <div class="fare-show">
              <v-card
                rounded="2"
                max-height="500px"
                class="pa-1"
              >
                <div class="d-flex justify-content-between m-1">
                  <div>
                    <p class="m-0" style="font-size: 26px; font-weight: 500">
                      {{ $t("fareDetContent.totalPrice") }}
                    </p>
                  </div>
                  <div>
                    <p style="font-size: 26px; font-weight: 500">
                      {{ currency }} {{ totalAmountpassenger }}
                    </p>
                  </div>
                </div>

                <div class="row mt-2 px-1">
                  <div class="col-md-12">
                    <!-- <div class="row justify-content-between">
                      <div class="col-6">
                        <span style="font-size: 14px; font-weight: 500"
                          >{{ $t("fareDetContent.passengers") }}</span
                        >
                      </div>
                      <div class="col-6 text-end" style="font-size: 14px">
                        <span v-if="adult > 0">{{ adult }} x {{ $t("fareDetContent.adult") }}</span><br />
                        <span v-if="child > 0">{{ child }} x {{ $t("fareDetContent.child") }}</span><br />
                        <span v-if="inf > 0">{{ inf }} x {{ $t("fareDetContent.infant") }}</span>
                      </div>
                    </div> -->
                    <div class="row justify-content-between">
                    <div class="col-md-5 col-4">
                      <span class="f-size-14 fw-500">{{
                        $t("fareDetContent.passengers")
                      }}</span>
                    </div>
                    <!-- <div class="col-md-4"></div> -->
                    <div class="col-md-7 col-7 f-size-14 text-end">
                      <span v-if="adult > 0">{{ adult }} x Adt</span>
                      <span v-if="child > 0">, {{ child }} x Chd</span>
                      <span v-if="inf > 0">, {{ inf }} x Inf</span>
                    </div>
                  </div>
                  </div>
                </div>

                <div class="mt-3 mx-1">
                  <hr class="mt-1 mb-2" />
                  <!-- <div class="d-flex align-end mb-2">
                  <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example" aria-expanded="true"
                    aria-controls="collapse-Example" style="
                      padding-right: 5px;
                      cursor: pointer;
                      font-size: 14px;
                      letter-spacing: 1px;
                    ">
                    <span style="color:0D6EFD; text-decoration:underline;">Fare Summary</span>
                  </span>

                  <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                    aria-expanded="true" aria-controls="collapse-Example" v-model="isIcon" style="
                      color: 0D6EFD;
                      cursor: pointer;
                      overflow-anchor: none;
                    ">
                    {{
                      isIcon
                      ? "mdi-chevron-up-box-outline"
                      : "mdi-chevron-down-box-outline"
                    }}</v-icon>
                </div> -->
                 
                  <div class="d-flex align-end pb-2 border-2">
                    <span
                      class="not-collapsed f-size-14"
                      data-bs-toggle="collapse"
                      href="#collapse-Example"
                      aria-expanded="true"
                      aria-controls="collapse-Example"
                      @click="isIcon = !isIcon"
                      style="
                        padding-right: 5px;
                        cursor: pointer;
                        letter-spacing: 1px;">
                      <span class="fw-600"
                        >{{ $t("fareDetContent.fareSummary") }}
                        <v-icon
                          size="18"
                          class="not-collapsed ms-1"
                          data-bs-toggle="collapse"
                          href="#collapse-Example"
                          aria-expanded="true"
                          aria-controls="collapse-Example">
                          {{isIcon ? "mdi-arrow-up-drop-circle-outline" : "mdi-information-outline"
                          }}</v-icon>
                      </span>
                    </span>
                  </div>
                  <!-- <div class="p-1 collapse" :class="{ show: isIcon }" id="collapse-Example"> -->
                   <div
                    v-for="(res, index) of getDataResult"
                    :key="index"
                    id="collapse-Example"
                    class="collapse bordeer-top-0 border border-2 border-top-0"
                    :class="{ show: isIcon }"
                  >
                    <div class="comDetailSection">
                      <div class="paxpricesection">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr class="f-size-12 fw-500">
                              <td style="background: #dee2e6">Pax</td>
                              <td style="background: #dee2e6">Base</td>
                              <td style="background: #dee2e6">Tax</td>
                              <td style="background: #dee2e6">Per Person</td>
                            </tr>
                          </thead>
                          <tbody class="f-size-10 fw-400">
                            {{
                              console.log(res, "priceDetailllll")
                            }}
                            <template v-for="(priceDetail, paxindex) in res.priceDetails" :key="paxindex">
                              <tr v-if="priceDetail && priceDetail.pax_type && priceDetail.baseprice && priceDetail.taxprice">
                                <td>{{ translatePaxType(priceDetail.pax_type) }}</td>
                                <td>{{ priceDetail.baseprice }}</td>
                                <td>{{ priceDetail.taxprice }}</td>
                                <td>{{ getperpersonPrice(priceDetail) }}</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                <!-- </div> -->

                <hr class="mt-1 mb-2" />
                <div class="row">
                    <div class="col-6 f-size-14">
                      {{ $t("fareDetContent.basePrice") }}<br />
                      {{ $t("fareDetContent.taxesFees") }}
                    </div>
                    <!-- <div class="col-6" align="right">
                      {{ currency }} {{ totalbasePassanger }}<br />
                      {{ currency }} {{ totalTaxPassanger }}
                    </div> -->
                    <div class="col-6 f-size-14">
                      <div class="d-flex flex-row justify-end">
                        <div class="col-5 text-end pe">
                          {{ currency }} <br />
                          {{ currency }}
                        </div>
                        <div class="col-7 text-end">
                          {{ totalbasePassanger }}<br />
                          {{ totalTaxPassanger }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr class="mt-1 mb-2" />
                   <div class="d-flex align-end mb-2">
                  <span
                    class="not-collapsed"
                    style="
                      padding-right: 5px;
                      cursor: pointer;
                      font-size: 14px;
                      letter-spacing: 1px;
                    ">
                    <a
                      class="baggage border-none"
                      style="
                        font-size: 14px;
                        color: rgb(13, 110, 253);
                        text-decoration: underline;
                      ">
                      <span
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop1"
                        aria-controls="staticBackdrop"
                        @click="getFareRules()"
                        >{{ $t("baggageDetContent.contents.baggAndRules") }}</span
                      >
                    </a>
                    <div class="drawer-section">
                      <div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" id="staticBackdrop1" aria-labelledby="staticBackdropLabel">
                      <div class="offcanvas-header d-flex justify-content-between" style="
                            background: #5293cb !important;
                            color: #fff !important;
                          ">
                        <h5 class="offcanvas-title p-0" id="staticBackdropLabel">{{ $t("baggageDetContent.contents.baggAndRules") }}</h5>
                        <button
                            @click="closeBaggageRules()"
                            type="button"
                            class="text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            color="white"
                          >
                            <v-icon>mdi-close</v-icon>
                            </button>
                      </div>
                      <div class="offcanvas-body">
                          <v-card class="">
                            <v-tabs v-model="tab" align-tabs="center" stacked>
                              <v-tab value="tab-1" class="m-0">
                                <v-icon>mdi-book-cancel-outline</v-icon>
                                {{ $t("baggageDetContent.contents.cancelRules") }}
                              </v-tab>
                              <v-tab value="tab-2">
                                <v-icon>mdi-bag-checked</v-icon>
                                {{ $t("baggageDetContent.contents.baggage") }}
                              </v-tab>
                            </v-tabs>

                            <v-card-text class="pt-0">
                              <keep-alive>
                                <v-window v-model="tab">
                                   <div v-if="tab === 'tab-1'">
                                    <div class="p-2 cancellationRules">
                                       <div v-if="fareLoader" class="text-center" style="margin:auto;">
                                        {{ $t("baggageDetContent.contents.loaderText") }}
                                        <v-progress-circular color="primary" class ="ms-1" indeterminate></v-progress-circular>
                                      </div>
                                      <div v-else>
                                    <h6 class="mt-2">{{ $t("baggageDetContent.contents.cancelRules") }}:</h6>
                                    <div class="p-2 cancellationRules">
                                      <div v-html="fareRulesContent"></div>
                                    </div>
                                    </div>
                                    </div>
                                  </div>
                                  <div v-if="tab === 'tab-2'">
                                    <h6 class="mt-1">{{ $t("baggageDetContent.contents.baggageRules") }}:</h6>
                                    <div
                                      class="p-2 rounded"
                                      v-for="(data, index) of rountresult"
                                      :key="index"
                                    >
                                      <div>
                                        <div
                                          style="
                                            font-size: 15px;
                                            font-weight: 500;
                                          "
                                        >
                                          {{
                                            data.Depature[0].Departure
                                              .AirportCode
                                          }}
                                          -
                                          {{
                                            data.Depature[
                                              data.Depature.length - 1
                                            ].Arrival.AirportCode
                                          }}
                                        </div>
                                        <div class="ms-2 mt-2">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-checked
                                              </v-icon></span
                                            >
                                            <span>{{ $t("baggageDetContent.contents.carryOn") }}:</span>
                                            <span
                                              v-if="data.depBaggage"
                                              class="ms-1"
                                            >
                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                  : 1 + " " + $t("baggageDetContent.contents.piece")
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="ms-2 mt-2">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-suitcase
                                              </v-icon></span
                                            >
                                            <span> {{ $t("baggageDetContent.contents.checked") }}:</span>
                                            <span
                                              v-if="data.depBaggage"
                                              class="ms-1"
                                            >
                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity || 0
                                              }}

                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity > 1
                                                  ? $t("baggageDetContent.contents.pieces")
                                                  : $t("baggageDetContent.contents.piece")
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <hr v-if="data.Return" />
                                      <div v-if="data.Return" class="mt-2">
                                        <div
                                          class="mt-1"
                                          style="
                                            font-size: 15px;
                                            font-weight: 500;
                                          "
                                        >
                                          {{
                                            data.Depature[
                                              data.Depature.length - 1
                                            ].Arrival.AirportCode
                                          }}
                                          -
                                          {{
                                            data.Depature[0].Departure
                                              .AirportCode
                                          }}
                                        </div>
                                        
                                        <div class="ms-2 mt-2">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-checked
                                              </v-icon></span
                                            >
                                            <span>{{ $t("baggageDetContent.contents.carryOn") }}:</span>
                                            <span class="ms-1">
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                  : 1 + " " + $t("baggageDetContent.contents.piece")
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="ms-2 mt-1">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-suitcase
                                              </v-icon></span
                                            >
                                            <span> {{ $t("baggageDetContent.contents.checked") }}:</span>
                                            
                                            <span class="ms-1">
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity || 0
                                              }}
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity > 1
                                                  ? $t("baggageDetContent.contents.pieces")
                                                  : $t("baggageDetContent.contents.piece")
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="onewayresult">
                                      <div
                                        class="p-2 rounded"
                                        v-for="(data, index) of onewayresult"
                                        :key="index"
                                      >
                                        <div v-if="data.BaggageData">
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Depature[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Depature[
                                                data.Depature.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>{{ $t("baggageDetContent.contents.carryOn") }}:</span>
                                              <span
                                                v-if="data.BaggageData"
                                                class="ms-1"
                                              >
                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                    : 1 + " " + $t("baggageDetContent.contents.piece")
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> {{ $t("baggageDetContent.contents.checked") }}:</span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}

                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? $t("baggageDetContent.contents.pieces")
                                                    : $t("baggageDetContent.contents.piece")
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-else>{{ $t("baggageDetContent.contents.errText") }}</div>
                                      </div>
                                    </div>
                                    <div v-if="multiresult">
                                      <div
                                        class="p-2 rounded"
                                        v-for="(data, index) of multiresult"
                                        :key="index"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight1[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight1[
                                                data.Flight1.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>{{ $t("baggageDetContent.contents.carryOn") }}: </span>
                                              <span class="ms-1">{{
                                                data.BaggageData1.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                  : 1 + " " + $t("baggageDetContent.contents.piece")
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> {{ $t("baggageDetContent.contents.checked") }}: </span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? $t("baggageDetContent.contents.pieces")
                                                    : $t("baggageDetContent.contents.piece")
                                                }}</span
                                              >
                                            </div>
                                          </div>

                                          <div class="ms-2 mt-2">
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight2" />
                                        <div v-if="data.Flight2" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight2[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight2[
                                                data.Flight2.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>{{ $t("baggageDetContent.contents.carryOn") }}: </span>
                                              <span class="ms-1">{{
                                                data.BaggageData2.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                  : 1 + " " + $t("baggageDetContent.contents.piece")
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> {{ $t("baggageDetContent.contents.checked") }}: </span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? $t("baggageDetContent.contents.pieces")
                                                    : $t("baggageDetContent.contents.piece")
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight3" />
                                        <div v-if="data.Flight3" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight3[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight3[
                                                data.Flight3.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>{{ $t("baggageDetContent.contents.carryOn") }}: </span>
                                              <span class="ms-1">{{
                                                data.BaggageData3.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                  : 1 + " " + $t("baggageDetContent.contents.piece")
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> {{ $t("baggageDetContent.contents.checked") }}: </span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? $t("baggageDetContent.contents.pieces")
                                                    : $t("baggageDetContent.contents.piece")
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight4" />
                                        <div v-if="data.Flight4" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight4[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight4[
                                                data.Flight4.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>{{ $t("baggageDetContent.contents.carryOn") }}: </span>
                                              <span class="ms-1">{{
                                                data.BaggageData4.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                  : 1 + " " + $t("baggageDetContent.contents.piece")
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="d-flex ms-2 mt-2">
                                            <div
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span
                                                ><v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> {{ $t("baggageDetContent.contents.checked") }}: </span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? $t("baggageDetContent.contents.pieces")
                                                    : $t("baggageDetContent.contents.piece")
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </v-window>
                              </keep-alive>
                            </v-card-text>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>

                <hr class="mt-1 mb-2" />
                  <div>
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("fareDetContent.passengers") }}
                    </div>

                    <div
                      class="mt-2"
                      v-for="(data, index) of totalPassData"
                      :key="index"
                    >
                      <div
                        style="
                          text-transform: uppercase;
                          font-size: 12px;
                          font-weight: 500;
                        "
                      >
                        {{ data.salutation }} {{ data.firstname }}
                        {{ data.middlename }} {{ data.lastname }}
                      </div>
                    </div>
                  </div>

                  <!-- <div
                    class="p-1 collapse"
                    :class="{ show: isIcon }"
                    id="collapse-Example"
                  >
                    <div class="row">
                      <div class="col-6">
                        Base Price<br />
                        Taxes & Fees
                      </div>
                      <div class="col-6" align="center">
                        {{ currency }} {{ totalbasePassanger }}<br />
                        {{ currency }} {{ totalTaxPassanger }}
                      </div>
                    </div>
                  </div> -->
                    <!-- <br>
                  <p style="font-size: 12px">
                    All fares are quoted in USD. Some airlines may charge baggage fees.
                    Your credit/debit card may be billed in multiple charges totaling
                    the final total price.
                  </p> -->

                  <br />
                  <p style="font-size: 12px">
                    {{ $t("fareDetContent.priceInfo1") }} {{metaCurrency}}. {{ $t("fareDetContent.priceInfo2") }}
                  </p>
                </div>
              </v-card>
            </div>

            <div class="mt-3 p-0" v-for="(res, index) of rountresult" :key="index">
              <div class="hoveredCard">
                <div class="row">
                    <div class="col-12 col-md-6">
                      <h4>{{ $t("paymentContent.headingContent.tripDetails") }}</h4>
                    </div>
                  </div>
                <v-card rounded="0" style="padding: 20px 0px; border-top: 4px solid rgb(37 34 94)">
                  <div class="row m-1">
                    <div class="col-lg-12">
                      <div class="mt-2">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 align-center">
                              <div class="col-md-3 col-sm-3 col-3 mb-3 mb-md-0 pe-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 m-md-auto">
                                    {{ getlogo(res.Depature) }}
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12 pe-0">
                                    <p class="m-0 d-flex flex-column ps-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Depature)
                                      }}</span>
                                      <!-- <br/> -->
                                     <span class="f-size-9 air-id fw-normal">
                                          {{
                                            res.Depature[0].MarketingCarrier.AirlineID
                                          }}-{{
                                            res.Depature[0].MarketingCarrier.FlightNumber
                                          }}
                                        </span>
                                      <!-- <span
                                        v-for="(result, index) of res.Depature"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}
                                        </span>

                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Depature.length - 1"
                                        >
                                          /
                                        </span>
                                      </span> -->
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9 col-sm-6">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Depature)
                                    }}</span>
                                    <span
                                      class="f-size-14"
                                      style="margin-left: 5px"
                                      >{{ getTime(res.Depature) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-14 fw-500">{{
                                      getAirportCode(res.Depature)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getDepAirportName(res.Depature)
                                      }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Depature)
                                    }}</span>
                                    <span
                                      class="f-size-15"
                                      style="margin-left: 5px"
                                      >{{ getArrivalTime(res.Depature) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-15 fw-500"
                                      >{{ getArrivalCode(res.Depature) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getArrAirportName(res.Depature)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-sm-1 col-3 pe-0 ps-3 mt-1">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Depature) }} {{ getDepStops(res.Depature) > 1 ?  $t("flightInfoContent.contents.stops") : $t("flightInfoContent.contents.stop") }} 
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Depature"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-sm-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section d-flex flex-column mt-1">
                                  <!-- <v-icon size="18">mdi-clock-outline </v-icon> -->
                                  <span class="f-size-14">{{ $t("flightInfoContent.contents.duration") }}: </span>
                                  <span class="f-size-14">{{
                                    res.Depaturejourney.Time
                                  }}</span
                                  >
                                  <!-- <br /> -->
                                  <!-- <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ depfligtTime(res.Depature) }})</span> -->
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>

                        <br />

                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 align-center">
                              <div class="col-md-3 col-sm-3 col-3 mb-3 mb-md-0 pe-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 m-md-auto">
                                    {{ getlogo1(res.Return) }}
                                    <span
                                      v-for="(result, index) of logo1"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12 pe-0">
                                    <p class="m-0 d-flex flex-column ps-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Return)
                                      }}</span>
                                      <!-- <br /> -->
                                      <span class="f-size-9 air-id fw-normal"
                                          >{{
                                            res.Return[0].MarketingCarrier.AirlineID
                                          }}-{{
                                            res.Return[0].MarketingCarrier.FlightNumber
                                          }}</span
                                        >
                                      <!-- <span
                                        v-for="(result, index) of res.Return"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal"
                                          >{{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}</span
                                        >
                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Return.length - 1"
                                        >
                                          /
                                        </span>
                                      </span> -->
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9 col-sm-6">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getreturnDate(res.Return)
                                    }}</span>
                                    <span
                                      class="f-size-14"
                                      style="margin-left: 5px"
                                      >{{ getreturnTime(res.Return) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-14 fw-500">
                                      {{
                                        getreturnAirportCode(res.Return)
                                      }}</span
                                    >
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getArrAirportName(res.Depature)
                                      }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getreturnArrivaldate(res.Return)
                                    }}</span>
                                    <span
                                      class="f-size-14"
                                      style="margin-left: 5px"
                                      >{{
                                        getreturnArrivalTime(res.Return)
                                      }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-14 fw-500">
                                      {{ getreturnArrivalcode(res.Return) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <!-- kkkk -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getDepAirportName(res.Depature)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-sm-1 col-3 pe-0 ps-3 mt-1">
                                <p class="f-size-12 text-center m-0">
                                  {{ getReturnStops(res.Return) }} {{ getReturnStops(res.Return) > 1 ? $t("flightInfoContent.contents.stops") : $t("flightInfoContent.contents.stop") }}
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Return"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-sm-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section d-flex flex-column mt-1">
                                  <!-- <v-icon size="18">mdi-clock-outline </v-icon> -->
                                  <span class="f-size-14">{{ $t("flightInfoContent.contents.duration") }}: </span>
                                  <span class="f-size-14">{{
                                    res.Returnjourney.Time
                                  }}</span
                                  >
                                  <!-- <br /> -->
                                  <!-- <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ retfligtTime(res.Return) }})</span> -->
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>

            <div class="mt-3 p-0" v-for="(res, index) of onewayresult" :key="index">
              <div class="hoveredCard">
                <div class="row">
                    <div class="col-12 col-md-6">
                      <h4>{{ $t("paymentContent.headingContent.tripDetails") }}</h4>
                    </div>
                  </div>
                <v-card rounded="0" style="padding: 20px 0px; border-top:4px solid rgb(37 34 94)">
                  <div class="row m-1">
                    <div class="col-lg-12">
                      <div class="mt-2">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 align-center">
                              <div class="col-md-3 col-sm-3 col-3 mb-3 mb-md-0 pe-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 m-md-auto">
                                    {{ getlogo(res.Depature) }}
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12 pe-0">
                                    <p class="m-0 d-flex flex-column ps-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Depature)
                                      }}</span>
                                      <!-- <br /> -->
                                     <span class="f-size-9 air-id fw-normal">
                                          {{
                                            res.Depature[0].MarketingCarrier.AirlineID
                                          }}-{{
                                            res.Depature[0].MarketingCarrier.FlightNumber
                                          }}
                                        </span>
                                      <!-- <span
                                        v-for="(result, index) of res.Depature"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}
                                        </span>

                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Depature.length - 1"
                                        >
                                          /
                                        </span>
                                      </span> -->
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9 col-sm-6">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Depature)
                                    }}</span>
                                    <span
                                      class="f-size-14"
                                      style="margin-left: 5px"
                                      >{{ getTime(res.Depature) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-14 fw-500">{{
                                      getAirportCode(res.Depature)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getDepAirportName(res.Depature)
                                      }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Depature)
                                    }}</span>
                                    <span
                                      class="f-size-15"
                                      style="margin-left: 5px"
                                      >{{ getArrivalTime(res.Depature) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-15 fw-500"
                                      >{{ getArrivalCode(res.Depature) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getArrAirportName(res.Depature)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-sm-1 col-3 pe-0 ps-3 mt-1">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Depature) }} {{ getDepStops(res.Depature) > 1 ? $t("flightInfoContent.contents.stops") : $t("flightInfoContent.contents.stop") }}
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Depature"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-sm-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section d-flex flex-column mt-1">
                                  <!-- <v-icon size="18">mdi-clock-outline </v-icon> -->
                                  <span class="f-size-14">{{ $t("flightInfoContent.contents.duration") }}: </span>
                                  <span class="f-size-14">{{
                                    res.Depaturejourney.Time
                                  }}</span
                                  >
                                  <!-- <br /> -->
                                  <!-- <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ depfligtTime(res.Depature) }})</span> -->
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>

            <div class="mt-3 p-0" v-for="(res, index) of multiresult" :key="index">
              <div class="hoveredCard">
                <div class="row">
                    <div class="col-12 col-md-6">
                      <h4>{{ $t("paymentContent.headingContent.tripDetails") }}</h4>
                    </div>
                  </div>
                <v-card rounded="0" style="padding: 20px 0px; border-top: 4px solid rgb(37 34 94)">
                  <div class="row m-1">
                    <div class="col-lg-12">
                      <div class="mt-2">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 align-center">
                              <div class="col-md-3 col-sm-3 col-3 mb-3 mb-md-0 pe-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 m-md-auto">
                                    {{ getlogo(res.Flight1) }}
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12 pe-0">
                                    <p class="m-0 d-flex flex-column ps-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Flight1)
                                      }}</span>
                                      <!-- <br /> -->
                                     <span class="f-size-9 air-id fw-normal">
                                          {{
                                            res.Flight1[0].MarketingCarrier.AirlineID
                                          }}-{{
                                            res.Flight1[0].MarketingCarrier.FlightNumber
                                          }}
                                        </span>
                                      <!-- <span
                                        v-for="(result, index) of res.Depature"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}
                                        </span>

                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Depature.length - 1"
                                        >
                                          /
                                        </span>
                                      </span> -->
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9 col-sm-6">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Flight1)
                                    }}</span>
                                    <span
                                      class="f-size-14"
                                      style="margin-left: 5px"
                                      >{{ getTime(res.Flight1) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-14 fw-500">{{
                                      getAirportCode(res.Flight1)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getDepAirportName(res.Flight1)
                                      }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Flight1)
                                    }}</span>
                                    <span
                                      class="f-size-15"
                                      style="margin-left: 5px"
                                      >{{ getArrivalTime(res.Flight1) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-15 fw-500"
                                      >{{ getArrivalCode(res.Flight1) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getArrAirportName(res.Flight1)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-sm-1 col-3 pe-0 ps-3 mt-1">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Flight1) }} {{ getDepStops(res.Flight1) > 1 ? $t("flightInfoContent.contents.stops") : $t("flightInfoContent.contents.stop") }}
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Flight1"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-sm-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section d-flex flex-column">
                                  <!-- <v-icon size="18">mdi-clock-outline </v-icon> -->
                                  <span class="f-size-14">{{ $t("flightInfoContent.contents.duration") }}: </span>
                                  <span class="f-size-14">{{
                                    res.Flight1JourneyTime.Time
                                  }}</span
                                  >
                                  <!-- <br /> -->
                                  <!-- <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ depfligtTime(res.Depature) }})</span> -->
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div class="mt-2">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 align-center">
                              <div class="col-md-3 col-sm-3 col-3 mb-3 mb-md-0 pe-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 m-md-auto">
                                    {{ getlogo(res.Flight2) }}
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12 pe-0">
                                    <p class="m-0 d-flex flex-column ps-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Flight2)
                                      }}</span>
                                      <!-- <br /> -->
                                     <span class="f-size-9 air-id fw-normal">
                                          {{
                                            res.Flight2[0].MarketingCarrier.AirlineID
                                          }}-{{
                                            res.Flight2[0].MarketingCarrier.FlightNumber
                                          }}
                                        </span>
                                      <!-- <span
                                        v-for="(result, index) of res.Depature"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}
                                        </span>

                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Depature.length - 1"
                                        >
                                          /
                                        </span>
                                      </span> -->
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9 col-sm-6">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Flight2)
                                    }}</span>
                                    <span
                                      class="f-size-14"
                                      style="margin-left: 5px"
                                      >{{ getTime(res.Flight2) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-14 fw-500">{{
                                      getAirportCode(res.Flight2)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getDepAirportName(res.Flight2)
                                      }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Flight2)
                                    }}</span>
                                    <span
                                      class="f-size-15"
                                      style="margin-left: 5px"
                                      >{{ getArrivalTime(res.Flight2) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-15 fw-500"
                                      >{{ getArrivalCode(res.Flight2) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getArrAirportName(res.Flight2)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-sm-1 col-3 pe-0 ps-3 mt-1">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Flight2) }} {{ getDepStops(res.Flight2) > 1 ? $t("flightInfoContent.contents.stops") : $t("flightInfoContent.contents.stop") }}
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Flight2"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-sm-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section d-flex flex-column">
                                  <!-- <v-icon size="18">mdi-clock-outline </v-icon> -->
                                  <span class="f-size-14">{{ $t("flightInfoContent.contents.duration") }}: </span>
                                  <span class="f-size-14">{{
                                    res.Flight2JourneyTime.Time
                                  }}</span
                                  >
                                  <!-- <br /> -->
                                  <!-- <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ depfligtTime(res.Depature) }})</span> -->
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div class="mt-2" v-if="res.Flight3 ? true : false">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 align-center">
                              <div class="col-md-3 col-sm-3 col-3 mb-3 mb-md-0 pe-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 m-md-auto">
                                    {{ getlogo(res.Flight3) }}
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12 pe-0">
                                    <p class="m-0 d-flex flex-column ps-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Flight3)
                                      }}</span>
                                      <!-- <br /> -->
                                     <span class="f-size-9 air-id fw-normal">
                                          {{
                                            res.Flight3[0].MarketingCarrier.AirlineID
                                          }}-{{
                                            res.Flight3[0].MarketingCarrier.FlightNumber
                                          }}
                                        </span>
                                      <!-- <span
                                        v-for="(result, index) of res.Depature"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}
                                        </span>

                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Depature.length - 1"
                                        >
                                          /
                                        </span>
                                      </span> -->
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9 col-sm-6">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Flight3)
                                    }}</span>
                                    <span
                                      class="f-size-14"
                                      style="margin-left: 5px"
                                      >{{ getTime(res.Flight3) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-14 fw-500">{{
                                      getAirportCode(res.Flight3)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getDepAirportName(res.Flight3)
                                      }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Flight3)
                                    }}</span>
                                    <span
                                      class="f-size-15"
                                      style="margin-left: 5px"
                                      >{{ getArrivalTime(res.Flight3) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-15 fw-500"
                                      >{{ getArrivalCode(res.Flight3) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getArrAirportName(res.Flight3)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-sm-1 col-3 pe-0 ps-3 mt-1">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Flight3) }} {{ getDepStops(res.Flight3) > 1 ? $t("flightInfoContent.contents.stops") : $t("flightInfoContent.contents.stop") }}
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Flight3"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-sm-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section d-flex flex-column">
                                  <!-- <v-icon size="18">mdi-clock-outline </v-icon> -->
                                  <span class="f-size-14">{{ $t("flightInfoContent.contents.duration") }}: </span>
                                  <span class="f-size-14">{{
                                    res.Flight3JourneyTime.Time
                                  }}</span
                                  >
                                  <!-- <br /> -->
                                  <!-- <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ depfligtTime(res.Depature) }})</span> -->
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div class="mt-2" v-if="res.Flight4 ? true : false">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 align-center">
                              <div class="col-md-3 col-sm-3 col-3 mb-3 mb-md-0 pe-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 m-md-auto">
                                    {{ getlogo(res.Flight4) }}
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12 pe-0">
                                    <p class="m-0 d-flex flex-column ps-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Flight4)
                                      }}</span>
                                      <!-- <br /> -->
                                     <span class="f-size-9 air-id fw-normal">
                                          {{
                                            res.Flight4[0].MarketingCarrier.AirlineID
                                          }}-{{
                                            res.Flight4[0].MarketingCarrier.FlightNumber
                                          }}
                                        </span>
                                      <!-- <span
                                        v-for="(result, index) of res.Depature"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}
                                        </span>

                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Depature.length - 1"
                                        >
                                          /
                                        </span>
                                      </span> -->
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9 col-sm-6">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Flight4)
                                    }}</span>
                                    <span
                                      class="f-size-14"
                                      style="margin-left: 5px"
                                      >{{ getTime(res.Flight4) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-14 fw-500">{{
                                      getAirportCode(res.Flight4)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getDepAirportName(res.Flight4)
                                      }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Flight4)
                                    }}</span>
                                    <span
                                      class="f-size-15"
                                      style="margin-left: 5px"
                                      >{{ getArrivalTime(res.Flight4) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-15 fw-500"
                                      >{{ getArrivalCode(res.Flight4) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getArrAirportName(res.Flight4)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-sm-1 col-3 pe-0 ps-3 mt-1">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Flight4) }} {{ getDepStops(res.Flight4) > 1 ? $t("flightInfoContent.contents.stops") : $t("flightInfoContent.contents.stop") }}
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Flight4"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-sm-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section d-flex flex-column">
                                  <!-- <v-icon size="18">mdi-clock-outline </v-icon> -->
                                  <span class="f-size-14">{{ $t("flightInfoContent.contents.duration") }}: </span>
                                  <span class="f-size-14">{{
                                    res.Flight4JourneyTime.Time
                                  }}</span
                                  >

                                  <!-- <br /> -->
                                  <!-- <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ depfligtTime(res.Depature) }})</span> -->
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>

            <div class="mt-3">
                <div class="row">
                    <div class="col-12 col-md-6">
                      <h4 style="padding-top: 20px">
                        {{ $t("fareDetContent.paymentDetails") }}
                      </h4>
                    </div>
                  </div>
                <v-card rounded="1" style="font-size: 14px; border-top: 4px solid rgb(37 34 94)">
                  <div class="p-4">
                    <div class="row">
                      <div class="col-md-5 col-sm-6 cardValidation">
                        <!-- <p class="mb-1">Card Number <span class="text-danger">*</span></p> -->
                        <v-text-field
                          :rules="cardNumberValid"
                          v-model="billingDetails.cardnumber"
                          @paste.prevent
                          @keypress="formatCardnumber(event)"
                          @keyup="identifyCard"
                          :label="this.$t('paymentContent.labelsContent.cardNumber')"
                          placeholder="xxxx-xxxx-xxxx-xxxx"
                          variant="outlined"
                          tabindex="1"
                          class="card_Number"
                        >
                        <template v-slot:append-inner>
                           <!-- <img alt="" height="30" :src="cardImage" width="60"> -->
                          <img v-if="showImage('visa')" :src="require('@/assets/img/credit_cards/visa.png')"
                            class="card" id="visa">
                          <img v-if="showImage('mastercard')"
                            :src="require('@/assets/img/credit_cards/mastercard.png')" class="card"
                            id="mastercard">
                          <img v-if="showImage('american-express')"
                            :src="require('@/assets/img/credit_cards/american_express.png')"
                            class="card" id="american-express">
                          <img v-if="showImage('discover')"
                            :src="require('@/assets/img/credit_cards/discover.png')" class="card"
                            id="discover">
                          <img v-if="showImage('jcb')" :src="require('@/assets/img/credit_cards/jcb.png')"
                            class="card" id="jcb">
                          <img v-if="showImage('diners-club')"
                            :src="require('@/assets/img/credit_cards/diners_club.png')" class="card"
                            id="diners-club">
                          <img v-if="showImage('maestro')"
                            :src="require('@/assets/img/credit_cards/maestro.png')" class="card"
                            id="maestro">
                        </template>
                        <template v-slot:append>
                          <v-icon :color="iconColor">{{ appendIcon }}</v-icon>
                        </template></v-text-field>
                      </div>
                      <div class="col-md-3 col-sm-6 ps-md-0 ps-3 cardName">
                        <!-- <p class="mb-1">Name on the card <span class="text-danger">*</span></p> -->
                        <v-text-field
                          :rules="cardNameValid"
                          type="text"
                          :label="this.$t('paymentContent.labelsContent.NameonCard')"
                          v-model="billingDetails.cardholdername"
                          @keypress="filtercardName(event)"
                          variant="outlined"
                          tabindex="2"
                        ></v-text-field>
                      </div>
                      <div class="col-md-2 col-sm-4 col-8 ps-md-0 ps-3 cardExp">
                        <!-- <p class="mb-1">Expire Date <span class="text-danger">*</span></p> -->
                        <v-text-field
                          v-model="billingDetails.ExpireDate"
                          :rules="expDate"
                          @keydown="handleInput"
                          @paste.prevent
                          :label="this.$t('paymentContent.labelsContent.expiry')"
                          @input="formatExpireDate"
                          @keypress="filterExpdate(event)"
                          variant="outlined"
                          @keydown.tab="focusNext"
                          tabindex="3"
                        ></v-text-field>
                      </div>
                      <div class="col-md-2 col-sm-3 col-4 ps-md-0 ps-3 cardCvv">
                        <!-- <p class="mb-1">CVV <span class="text-danger">*</span></p> -->
                        <v-text-field
                          :rules="cvvValid"
                          :label="this.$t('paymentContent.labelsContent.cvvCode')"
                          v-model="billingDetails.cvvnumber"
                          :maxlength="showImage('american-express') ? 4 : 3"
                          variant="outlined"
                          name="cvv"
                          @paste.prevent
                          @keydown.tab="focusNext"
                          tabindex="4"
                          @keypress="filterNumber(event)"
                        ></v-text-field>
                      </div>
                    </div>

                    <div>
                      <div class="d-flex flex-sm-row flex-column mt-2">
                        <div>{{ $t("paymentContent.headingContent.cardText") }} :</div>
                        <div class="d-flex ps-2">
                          <v-img
                            :src="require('../assets/img/images/american_express.png')"
                            width="65px"
                          />
                          <v-img
                            :src="require('../assets/img/images/master_card.png')"
                            width="42px"
                          />
                          <v-img
                            :src="require('../assets/img/images/visa_card.png')"
                            width="65px"
                          />
                          <v-img
                            :src="require('../assets/img/images/jcb.png')"
                            width="42px"
                          />
                          <v-img
                            :src="require('../assets/img/images/discover.png')"
                            width="46px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
            </div>

            <div class="mt-3">
                <div class="row">
                    <div class="col-12 col-md-6">
                      <h4 style="padding-top: 20px">
                        {{ $t("paymentContent.headingContent.billingHeading") }}
                      </h4>
                    </div>
                  </div>
                <v-card style="font-size: 14px; border-top: 4px solid rgb(29 36 121 / 86%)">
                  <div class="p-4">
                    <div class="row">
                      <div class="col-md-4">
                        <!-- <p class="mb-2">Full Name<span class="text-danger"> *</span></p> -->
                        <v-text-field
                          v-model="billingDetails.name"
                          :label="this.$t('paymentContent.labelsContent.fullName')"
                          :rules="[
                            (v) => !!v || 'Full Name is required',
                            (v) =>
                              /^[a-zA-Z -]+$/.test(v) ||
                              'Only alphabets, hyphens, and spaces are allowed',
                          ]"
                          @keypress="filterfullname(event)"
                          variant="outlined"
                        ></v-text-field>
                      </div>

                      <div class="col-md-4">
                        <v-autocomplete
                          :items="countriesData"
                          item-title="name"
                          item-value="name"
                          item-text="name"
                          variant="outlined"
                          :rules="[(v) => !!v || 'Country is required']"
                          :label="this.$t('paymentContent.labelsContent.country')"
                          v-model="billingDetails.country"
                          class="country-field"
                          ref="countryInput"
                          dense
                          hide-no-data
                        >
                        <template v-slot:item="{ item }">
                        <!-- {{ console.log(item,'countriesDatacountriesData.....3') }} -->
                          <div v-if="item.raw.type === 'separator'">
                            <hr class="dropdown-separator" />
                          </div>

                          <div v-else style="padding:10px 12px;" @click="selectCountry(item.raw.name)">
                            {{ item.raw.name }}
                          </div>
                        </template>
                        </v-autocomplete>

                          <!-- <div class="dropdown" ref="dropdown">
                            <div class="input-wrapper" :class="{ 'is-invalid': isCountryInvalid }">
                              <input
                                type="text"
                                v-model="searchTerm"
                                placeholder=""
                                @focus="toggleDropdown"
                                @keyup="filterCountries"
                                required ref="countryInput"
                              />
                               <label class="floating-label" :class="{ 'active': searchTerm }" style="pointer-events:none;">Country</label>
                             
                              <v-icon size="24" :class="{ 'rotate-icon': dropdownVisible }" style="color:gray;curosr:pointer" @click="toggleFocus()" class="">
                                mdi-menu-down
                              </v-icon>
                            </div>

                            <div v-if="dropdownVisible" class="dropdown-content">
                              <div v-for="(item, index) in filteredCountries" :key="index">

                                <hr v-if="item.type === 'separator'" class="dropdown-separator" />

                                <div v-else class="dropdown-item" @click="selectCountry(item.name)">
                                  {{ item.name }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="isCountryInvalid" class="error-message">
                            Country is Required
                          </div> -->
                      </div>

                      <div class="col-md-4">
                        <v-autocomplete
                          :items="stateData"
                          item-title="name"
                          variant="outlined"
                          :rules="[(v) => !!v || 'Place is required']"
                          :label="this.$t('paymentContent.labelsContent.stateLabel')"
                          v-model="billingDetails.state"
                          class="country-field"
                        >
                        </v-autocomplete>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <!-- <p class="mb-2">Address Line 2 (Optional)</p> -->

                        <v-text-field
                          :rules="[(v) => !!v || 'Address is required']"
                          v-model="billingDetails.address1"
                          :label="`${this.$t('paymentContent.labelsContent.addressLine')} 1`"
                          variant="outlined"
                        ></v-text-field>
                      </div>

                      <div class="col-md-4">
                        <!-- <p class="mb-2">Region/State/Province<span class="text-danger"> *</span></p> -->
                        <v-text-field
                          :label="`${this.$t('paymentContent.labelsContent.addressLine')} 2`"
                          variant="outlined"
                          v-model="billingDetails.address2"
                        ></v-text-field>

                        <!-- <v-select class="v-select" label="State" :items="['America', 'Canada']"
                                                      v-model="billingDetails.state"
                                                      :rules="[v => !!v || 'This field is required']"
                                                      variant="outlined"></v-select> -->
                      </div>

                      <div class="col-md-4">
                        <!-- <p class="mb-2">City<span class="text-danger"> *</span></p> -->
                        <v-text-field
                          :rules="[(v) => !!v || 'City is required']"
                          v-model="billingDetails.city"
                          @keypress="filterCity(event)"
                          :label="this.$t('paymentContent.labelsContent.city')"
                          variant="outlined"
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <!-- <p class="mb-2">Zip Code<span class="text-danger"> *</span></p> -->
                        <v-text-field
                          v-model="billingDetails.zipcode"
                          :label="this.$t('paymentContent.labelsContent.postelCode')"
                          :rules="[(v) => !!v || 'This field is required']"
                          variant="outlined"
                          @keypress="filterPostalcode(event)"
                        ></v-text-field>
                      </div>

                      <div class="col-md-4">
                        <v-autocomplete
                          :items="countrycode"
                          item-title="name"
                          variant="outlined"
                          width="auto"
                          :rules="[(v) => !!v || 'Country Code is required']"
                          :label="this.$t('paymentContent.labelsContent.countryCode')"
                          v-model="billingDetails.coutyCode"
                        >
                        </v-autocomplete>
                      </div>

                      <div class="col-md-4">
                        <v-text-field
                          :label="this.$t('paymentContent.labelsContent.phoneNumber')"
                          variant="outlined"
                          v-model="billingDetails.phonenumber"
                          @country-changed="handleCountryChanged"
                          @input="handleNumericInput"
                          :rules="[(v) => !!v || 'Phone Number is required']"
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-4">
                        <v-text-field
                          :label="this.$t('paymentContent.labelsContent.email')"
                          :rules="emailRequire"
                          variant="outlined"
                          v-model="billingDetails.email"
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex align-end">
                            <label
                              class="mb-1 d-flex justify-center align-center"
                              style="font-size: 13px"
                              >{{ $t('paymentContent.labelsContent.altContact') }}</label
                            >
                          </div>
                          <v-radio-group
                            class="alternate-Details"
                            inline
                            v-model="radioBtn"
                          >
                            <div class="d-flex align-items-end">
                              <v-radio
                                :label="this.$t('paymentContent.labelsContent.phone')"
                                value="1"
                                @click="
                                  (altContact = true), (mailAddress = false)
                                "
                              ></v-radio>
                              <v-radio
                                :label="this.$t('paymentContent.labelsContent.email')"
                                value="2"
                                @click="
                                  (mailAddress = true), (altContact = false)
                                "
                              ></v-radio>
                            </div>
                          </v-radio-group>
                        </div>

                        <!-- <vue-tel-input v-model="alterPhone" @input="handleNumericInput1"
                        
                        v-if="altContact"  @country-changed="handleCountryChanged"></vue-tel-input> -->

                        <v-text-field
                          v-model="billingDetails.email1"
                          type="email"
                          :label="this.$t('paymentContent.labelsContent.email')"
                          variant="outlined"
                          required
                          v-if="mailAddress"
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row mt-2" v-if="altContact">
                      <div class="col-md-4">
                        <v-autocomplete
                          :items="countrycode"
                          item-title="name"
                          variant="outlined"
                          width="auto"
                          :label="this.$t('paymentContent.labelsContent.countryCode')"
                          v-model="billingDetails.coutyCode1"
                        >
                        </v-autocomplete>
                      </div>

                      <div class="col-md-4">
                        <v-text-field
                          :label="this.$t('paymentContent.labelsContent.phoneNumber')"
                          variant="outlined"
                          v-model="billingDetails.phonenumber1"
                          @country-changed="handleCountryChanged"
                          @input="handleNumericInput"
                          @keypress="filterNumber(event)"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </v-card>
            </div>

            <div class="mt-5 mb-5 terms-text">
                <p class="text-center font-weight terms-text">
                  {{ $t("fareDetContent.confirmbtnText")}}  <a class="text-primary" style="color:#3e8cfd !important;" href="/page/terms-of-use">{{ $t("fareDetContent.termsofUse") }}</a>.
                </p>
                <div class="d-flex justify-center continue-section">
                  <v-btn
                    class="continue-btn text-capitalize"
                    type="submit"
                    color="#1b1464"
                    size="large"
                    >{{ $t("paymentContent.labelsContent.continueTobook") }}</v-btn
                  >
                </div>
            </div>
            <!-- <br />
            <br /> -->
          </div>
        </div>

        <div class="col-lg-3 col-md-3">
          <div class="fare-summery" style="margin-bottom: 146px" :style="{ top: topSticky }">
            <v-card
              max-width="450px"
              class="pa-2 m-auto"
              style="border: 1px solid grey"
            >
              <h5>{{ $t("fareDetContent.fareDetails") }}</h5>
              <hr />
              <div class="d-flex justify-content-between m-1">
                <div>
                  <p class="m-0" style="font-size: 16px; font-weight: 500">
                    {{ $t("fareDetContent.totalPrice") }}
                  </p>
                </div>
                <div>
                  <p class="tot-price m-0">
                    {{ currency }} {{ totalAmountpassenger }}
                  </p>
                </div>
              </div>

              <div class="row px-1">
                <div class="col-md-12">
                  <div class="row justify-content-between">
                      <div class="col-md-5">
                        <span class="f-size-14 fw-500">{{ $t("fareDetContent.passengers")}}</span>
                      </div>
                      <!-- <div class="col-md-4"></div> -->
                      <div class="col-md-7 ps-0 f-size-14 text-end">
                          <span v-if="adult > 0">{{ adult }} x Adt</span>
                          <span v-if="child > 0">, {{ child }} x Chd</span>
                          <span v-if="inf > 0">, {{ inf }} x Inf</span>
                      </div>
                    </div>
                </div>
              </div>

              <div class="mt-1 mx-1">
                <hr class="mt-1 mb-2" />
                <!-- <div class="d-flex align-end mb-2" style="cursor: pointer">
                  <span style="padding-right: 5px" @click="isIcon = !isIcon">
                    <span style="color: 0D6EFD; text-decoration: underline"
                      >Fare Summary</span
                    >
                  </span>

                  <v-icon
                    size="18"
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                    v-model="isIcon"
                    style="color: 0D6EFD; overflow-anchor: none"
                    >{{
                      isIcon
                        ? "mdi-chevron-up-box-outline"
                        : "mdi-chevron-down-box-outline"
                    }}</v-icon
                  >
                </div> -->
                <div class="d-flex align-end pb-2 border-2">
                      <span  class="not-collapsed f-size-14" data-bs-toggle="collapse" href="#collapse-Example" aria-expanded="true"
                        aria-controls="collapse-Example" @click="isIcon = !isIcon" style="padding-right: 5px;cursor: pointer;letter-spacing: 1px;">
                        <span class="fw-600">{{ $t("fareDetContent.fareSummary")}}
                          <v-icon size="18" class="not-collapsed ms-1" data-bs-toggle="collapse" href="#collapse-Example"
                            aria-expanded="true" aria-controls="collapse-Example">
                            {{ isIcon ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-information-outline' }}</v-icon>
                          </span>
                      </span>
                    </div>

                 <!-- <hr class="mt-1 mb-2" /> -->
                <div v-for="(res, index) of getDataResult" :key="index" 
                      id="collapse-Example" class="collapse bordeer-top-0 border border-2 border-top-0">

                        <div class="comDetailSection">
                          <div class="paxpricesection">
                            <table class="table table-borderless mb-0">
                              <thead>
                                <tr class="f-size-12 fw-500">
                                  <td style="background: #dee2e6;">Pax</td>
                                  <td style="background: #dee2e6;">Base</td>
                                  <td style="background: #dee2e6;">Tax</td>
                                  <td style="background: #dee2e6;">Per Person</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-10 fw-400">
                                  {{console.log(res,'priceDetailllll')}}
                                <template v-for="(priceDetail, paxindex) in res.priceDetails" :key="paxindex">
                                  <tr v-if="priceDetail && priceDetail.pax_type && priceDetail.baseprice && priceDetail.taxprice">
                                    <td>{{ translatePaxType(priceDetail.pax_type) }}</td>
                                    <td>{{ priceDetail.baseprice }}</td>
                                    <td>{{ priceDetail.taxprice }}</td>
                                    <td>{{ getperpersonPrice(priceDetail) }}</td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
         
                    </div>

                    <hr class="mt-1 mb-2" />

                  <div class="p-1">
                    <div class="row">
                      <div class="col-6 f-size-14">
                        {{ $t("fareDetContent.basePrice") }}<br />
                        {{ $t("fareDetContent.taxesFees") }}
                      </div>
                      <div class="col-6 f-size-14">
                        <div class="d-flex flex-row justify-end">
                          <div class="col-4 text-end pe">
                            {{ currency }} <br />
                            {{ currency }}
                          </div>
                          <div class="col-8 text-end pe-0">
                            {{ totalbasePassanger }}<br />
                            {{ totalTaxPassanger }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                <hr class="mt-1 mb-2" />
                 <div class="d-flex align-end mb-2">
                  <span
                    class="not-collapsed"
                    style="
                      padding-right: 5px;
                      cursor: pointer;
                      font-size: 14px;
                      letter-spacing: 1px;
                    "
                  >
                    <a
                      class="baggage border-none"
                      style="
                        font-size: 14px;
                        color: rgb(13, 110, 253);
                        text-decoration: underline;
                      "
                    >
                      <span
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop"
                        aria-controls="staticBackdrop"
                        @click="getFareRules()"
                        >{{ $t("baggageDetContent.contents.baggAndRules") }}</span
                      >
                    </a>
                    <div class="drawer-section">
                      <div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel" style="width: 35% !important">
                      <div class="offcanvas-header d-flex justify-content-between" style="
                            background: #5293cb !important;
                            color: #fff !important;
                          ">
                        <h5 class="offcanvas-title p-0" id="staticBackdropLabel">{{ $t("baggageDetContent.contents.baggAndRules") }}</h5>
                        <button
                            @click="closeBaggageRules()"
                            type="button"
                            class="text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            color="white"
                          >
                            <v-icon>mdi-close</v-icon>
                            </button>
                      </div>
                      <div class="offcanvas-body">
                          <v-card class="">
                            <v-tabs v-model="tab" align-tabs="center" stacked>
                              <v-tab value="tab-1" class="m-0">
                                <v-icon>mdi-book-cancel-outline</v-icon>
                                {{ $t("baggageDetContent.contents.cancelRules") }}
                              </v-tab>
                              <v-tab value="tab-2">
                                <v-icon>mdi-bag-checked</v-icon>
                                {{ $t("baggageDetContent.contents.baggage") }}
                              </v-tab>
                            </v-tabs>

                            <v-card-text class="pt-0">
                              <keep-alive>
                                <v-window v-model="tab">
                                   <div v-if="tab === 'tab-1'">
                                    <div class="p-2 cancellationRules">
                                       <div v-if="fareLoader" class="text-center" style="margin:auto;">
                                        {{ $t("baggageDetContent.contents.loaderText") }}
                                        <v-progress-circular color="primary" class ="ms-1" indeterminate></v-progress-circular>
                                      </div>
                                      <div v-else>
                                    <h6 class="mt-2">{{ $t("baggageDetContent.contents.cancelRules") }}:</h6>
                                    <div class="p-2 cancellationRules">
                                      <div v-html="fareRulesContent"></div>
                                    </div>
                                    </div>
                                    </div>
                                  </div>
                                  <div v-if="tab === 'tab-2'">
                                    <h6 class="mt-1">{{ $t("baggageDetContent.contents.baggageRules") }}:</h6>
                                    <div
                                      class="p-2 rounded"
                                      v-for="(data, index) of rountresult"
                                      :key="index"
                                    >
                                      <div>
                                        <div
                                          style="
                                            font-size: 15px;
                                            font-weight: 500;
                                          "
                                        >
                                          {{
                                            data.Depature[0].Departure
                                              .AirportCode
                                          }}
                                          -
                                          {{
                                            data.Depature[
                                              data.Depature.length - 1
                                            ].Arrival.AirportCode
                                          }}
                                        </div>
                                        <div class="ms-2 mt-2">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-checked
                                              </v-icon></span
                                            >
                                            <span>{{ $t("baggageDetContent.contents.carryOn") }}:</span>
                                            <span
                                              v-if="data.depBaggage"
                                              class="ms-1"
                                            >
                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                  : 1 + " " + $t("baggageDetContent.contents.piece")
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="ms-2 mt-2">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-suitcase
                                              </v-icon></span
                                            >
                                            <span> {{ $t("baggageDetContent.contents.checked") }}:</span>
                                            <span
                                              v-if="data.depBaggage"
                                              class="ms-1"
                                            >
                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity || 0
                                              }}

                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity > 1
                                                  ? $t("baggageDetContent.contents.pieces")
                                                  : $t("baggageDetContent.contents.piece")
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <hr v-if="data.Return" />
                                      <div v-if="data.Return" class="mt-2">
                                        <div
                                          class="mt-1"
                                          style="
                                            font-size: 15px;
                                            font-weight: 500;
                                          "
                                        >
                                          {{
                                            data.Depature[
                                              data.Depature.length - 1
                                            ].Arrival.AirportCode
                                          }}
                                          -
                                          {{
                                            data.Depature[0].Departure
                                              .AirportCode
                                          }}
                                        </div>
                                       
                                        <div class="ms-2 mt-2">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-checked
                                              </v-icon></span
                                            >
                                            <span>{{ $t("baggageDetContent.contents.carryOn") }}:</span>
                                            <span class="ms-1">
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                  : 1 + " " + $t("baggageDetContent.contents.piece")
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="ms-2 mt-1">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-suitcase
                                              </v-icon></span
                                            >
                                            <span> {{ $t("baggageDetContent.contents.checked") }}:</span>
                                           
                                            <span class="ms-1">
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity || 0
                                              }}
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity > 1
                                                  ? $t("baggageDetContent.contents.pieces")
                                                  : $t("baggageDetContent.contents.piece")
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="onewayresult">
                                      <div
                                        class="p-2 rounded"
                                        v-for="(data, index) of onewayresult"
                                        :key="index"
                                      >
                                        <div v-if="data.BaggageData">
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Depature[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Depature[
                                                data.Depature.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>{{ $t("baggageDetContent.contents.carryOn") }}:</span>
                                              <span
                                                v-if="data.BaggageData"
                                                class="ms-1"
                                              >
                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                    : 1 + " " + $t("baggageDetContent.contents.piece")
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> {{ $t("baggageDetContent.contents.checked") }}:</span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}

                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? $t("baggageDetContent.contents.pieces")
                                                    : $t("baggageDetContent.contents.piece")
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-else>{{ $t("baggageDetContent.contents.errText") }}</div>
                                      </div>
                                    </div>
                                    <div v-if="multiresult">
                                      <div
                                        class="p-2 rounded"
                                        v-for="(data, index) of multiresult"
                                        :key="index"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight1[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight1[
                                                data.Flight1.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>{{ $t("baggageDetContent.contents.carryOn") }}: </span>
                                              <span class="ms-1">{{
                                                data.BaggageData1.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                  : 1 + " " + $t("baggageDetContent.contents.piece")
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> {{ $t("baggageDetContent.contents.checked") }}: </span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? $t("baggageDetContent.contents.pieces")
                                                    : $t("baggageDetContent.contents.piece")
                                                }}</span
                                              >
                                            </div>
                                          </div>

                                          <div class="ms-2 mt-2">
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight2" />
                                        <div v-if="data.Flight2" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight2[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight2[
                                                data.Flight2.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>{{ $t("baggageDetContent.contents.carryOn") }}: </span>
                                              <span class="ms-1">{{
                                                data.BaggageData2.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                  : 1 + " " + $t("baggageDetContent.contents.piece")
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> {{ $t("baggageDetContent.contents.checked") }}: </span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? $t("baggageDetContent.contents.pieces")
                                                    : $t("baggageDetContent.contents.piece")
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight3" />
                                        <div v-if="data.Flight3" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight3[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight3[
                                                data.Flight3.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>{{ $t("baggageDetContent.contents.carryOn") }}: </span>
                                              <span class="ms-1">{{
                                                data.BaggageData3.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                  : 1 + " " + $t("baggageDetContent.contents.piece")
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> {{ $t("baggageDetContent.contents.checked") }}: </span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? $t("baggageDetContent.contents.pieces")
                                                    : $t("baggageDetContent.contents.piece")
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight4" />
                                        <div v-if="data.Flight4" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight4[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight4[
                                                data.Flight4.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>{{ $t("baggageDetContent.contents.carryOn") }}: </span>
                                              <span class="ms-1">{{
                                                data.BaggageData4.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " " + $t("baggageDetContent.contents.piece")
                                                  : 1 + " " + $t("baggageDetContent.contents.piece")
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="d-flex ms-2 mt-2">
                                            <div
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span
                                                ><v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> {{ $t("baggageDetContent.contents.checked") }}: </span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? $t("baggageDetContent.contents.pieces")
                                                    : $t("baggageDetContent.contents.piece")
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </v-window>
                              </keep-alive>
                            </v-card-text>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>

                <hr class="mt-1 mb-2" />
                <div>
                  <div style="font-size: 16px; font-weight: 500">
                    {{ $t("fareDetContent.passengers") }}
                  </div>

                  <div
                    class="mt-2"
                    v-for="(data, index) of totalPassData"
                    :key="index"
                  >
                    <div
                      style="
                        text-transform: uppercase;
                        font-size: 12px;
                        font-weight: 500;
                      "
                    >
                      {{ data.salutation }} {{ data.firstname }}
                      {{ data.middlename }} {{ data.lastname }}
                    </div>
                  </div>
                </div>

                <!-- <div
                  class="p-1 collapse"
                  :class="{ show: isIcon }"
                  id="collapse-Example"
                >
                  <div class="row">
                    <div class="col-6">
                      Base Price<br />
                      Taxes & Fees
                    </div>
                    <div class="col-6" align="center">
                      {{ currency }} {{ totalbasePassanger }}<br />
                      {{ currency }} {{ totalTaxPassanger }}
                    </div>
                  </div>
                </div> -->

                <br />
                <p style="font-size: 12px">
                  {{ $t("fareDetContent.priceInfo1") }} {{metaCurrency}}. {{ $t("fareDetContent.priceInfo2") }}
                </p>
              </div>

              <p class="font-weight f-size-11">
                {{ $t("fareDetContent.confirmbtnText") }} <a class="text-primary" style="color:#3e8cfd !important;" href="/page/terms-of-use">{{ $t("fareDetContent.termsofUse") }}</a>.
              </p>
            </v-card>

            <div class="mt-2 mb-2 continue-section" align="center">
              <v-btn
                height="40px"
                class="continue-btn-1"
                type="submit"
                style="
                  font-size: 16px;
                  font-weight: 500;
                  color: white;
                  text-transform: capitalize;
                  background-color: #26225e;
                "
              >
                {{ $t("paymentContent.labelsContent.continueTobook") }}</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </v-form>
  </v-container>

  <!-- <div class="text-center">
    <v-btn
      append-icon="mdi-open-in-new"
      color="deep-purple-accent-4"
      @click="overlay = !overlay"
    >
      Launch Application
    </v-btn>

    <v-overlay
      :model-value="overlay"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div> -->
  <div class="text-center" v-if="dataloader">
    <dataloader></dataloader>
  </div>

  <v-dialog v-model="dialog3" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">{{ $t("errorMsg.content2.text1") }}</h3>
        <h5>{{ $t("errorMsg.content2.text2") }}</h5>
        <h6>{{ $t("errorMsg.content2.text3") }}</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="dialog3 = false" color="#162849">{{ $t("errorMsg.buttons.tryAgain") }}</v-btn>

          <v-btn to="/" color="#162849">{{ $t("errorMsg.buttons.returnHome") }}</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="dialog4" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">{{ $t("errorMsg.content2.text1") }}</h3>
        <h5>{{ $t("errorMsg.content3.text1") }}</h5>
        <h6>{{ $t("errorMsg.content3.text2") }}</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <!-- <v-btn @click="dialog3 = false" color="#162849">Try Again</v-btn> -->

          <v-btn to="/" color="#162849">{{ $t("errorMsg.buttons.returnHome") }}</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import moment from "moment";
// import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import countries from "@/countries.json";
import majorCountries from "@/majorCountries.json";
import state from "@/statas.json";
import { ref } from "vue";
import axios from "axios";
import dataloader from "@/components/loaderComponent.vue";
import country1 from "@/coundtrycode.json";
import CardValidator from 'card-validator'

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

const month = ref({
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
});

export default {
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  components: {
    dataloader,
  },
  data() {
    return {
      shoppingid:"",
      selectId:"",
      tab:null,
      search:"",
      portalId:"",
      fareRulesContent:"",
      fareLoader:false,
      dataloader: true,
      showConfirmPage: false,
      metaCurrency: "",
      majorCountries:majorCountries,
      strResult: "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      dialog3: false,
      portal: false,
      countrycode: [],
      searchTerm:"",
      booking_Id: "",
      booking_refId: "",
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      topSticky: "0%",
      countriesData: [],
      selectedCountry: "",
      dropdownVisible: false,
      isCountryInvalid: false,
      stateData: [],
      datenumber: false,
      alterPhone: "",
      totalAmountpassenger: [],
      totalTaxPassanger: [],
      totalbasePassanger: [],
      det: {
        name: "",
        email: "",
      },
      multiresult: [],
      paxcontact: [],
      adult: "",
      child: "",
      inf: "",
      from: "",
      to: "",
      fromdate: "",
      todate: "",
      fromname: "",
      toname: "",
      fromcode: "",
      tocode: "",
      basePrice: "",
      taxprice: "",
      city: "",
      month,
      isIcon: false,
      passDeatail: [],

      adultDetailData: [],
      childDetailData: [],
      infDetailData: [],
      totalPassData: [],
      fromData: "",
      toData: "",
      adultLength: "",
      childLength: "",
      infantLength: "",
      classed: "",
      // flow,
      billingDetails: {
        cardnumber: "",
        cardholdername: "",
        ExpireDate: "",
        cvvnumber: "",
        logo: [],
        logo1: [],
        name: "",
        country: null,
        address1: "",
        address2: "",
        state: null,
        city: "",
        zipcode: "",
        phonenumber: "",
        email: "",
        phonenumber1: "",
        coutyCode: null,
        coutyCode1: null,
        email1: "",
      },
      depAirId: [],
      countryCode: "",
      retAirId: [],
      depAirLogo: [],
      retAirLogo: [],
      rountresult: [],
      totalmsg: [],
      bookinginiId: "",
      onewayresult: [],
      roundresult: [],
      currency: "",
      valid: false,
      altContact: true,
      mailAddress: false,
      local_id: "",
      Localoneway_id: "",
      // formatDate: "Month,year",
      emailId: "",
      phone: "",

      // expDate: [
      //     v => !!v || 'This field is required',
      //     v => /^(0[1-9]|1[0-2])\/\d{2}$/.test(v) || 'Invalid format (MM / YY)',
      //     v => /^[0-9/]+$/.test(v) || 'Only numbers and "/" are allowed',
      // ],

      expDate: [
        (v) => !!v || "Expiry Date is required",
        (v) => /^[0-9/]+$/.test(v) || 'Only numbers and "/" are allowed',
        (v) => {
          const [month, year] = v.split("/");

          const isInvalidFormat = !v.match(/^(0[1-9]|1[0-2])\/\d{2}$/);
          if (isInvalidFormat) return "Invalid format (MM / YY)";
              
          const inputMonth = parseInt(month);
          const inputYear = parseInt(year);
              
          const currentDate = new Date();
          const currentMonth = currentDate.getMonth() + 1;
          const currentYear = currentDate.getFullYear() % 100;
          const maxYear = currentYear + 10; 
              
          if (inputMonth < 1 || inputMonth > 12) return "Invalid month";
          if (inputYear < currentYear) return "Invalid year";
          if (inputYear === currentYear && inputMonth < currentMonth) return "Invalid month";
          if (inputYear > maxYear) return "Year exceeded max year";
              
          return true;
        },
      ],

      cardNumberValid: [
        (v) => !!v || "Card Number is required",
        (v) => {
          const numericOnly = v.replace(/\s+/g, '');
          return /^\d+$/.test(numericOnly) || "Only numeric characters are allowed";
        },
        // (v) => v.length === 16 || 'Card number must be 16 digits long'
      ],

      emailRequire: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],

      cardNameValid: [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],
      cvvValid: [
        (v) => !!v || "CVV is required",
        (v) => {
          // Validate card number using CardValidator
          const cardDetail = CardValidator.number(this.billingDetails.cardnumber.replace(/\D/g, ''));

          // Determine the CVV size based on the card type
          const cvvSize = cardDetail.card ? cardDetail.card.code.size : null;

          // Check if the card type was recognized and a CVV size is determined
          if (cvvSize === null) {
            return "Invalid CVV number";
          }

          // Validate the CVV length
          if (v.length !== cvvSize) {
            return `CVV must be ${cvvSize} digits`;
          }

          return true;
        }
      ],


      radioBtn: "1",
      todayDate: "",
      fullName: "",
      selectedCountries: [],
      addressLine: "",

      portalbookingUrl: "",

      userBooking: {
        booking_ref_id: "",
        portal_id: "",
        portal_domain:"",
        offer_id: "",
        pricingid:"",
        logged_in:false,
        logged_email:"",
        payment_enable: "",
        pointofsale: "",
        trip_type: "",
        hit_id: "",
        shopping_response_id: "",
        adult: 1,
        child: 0,
        paxInfant: 0,
        ip_address: "",
        search_cabin_type: "",
        pax_info: [],
        pax_contact: {
          countrycode: "",
          pax_phone: "",
          pax_email: "",
        },
        bill_info: {
          bill_fullname: "",
          bill_Country: "",
          bill_state: "",
          bill_address1: "",
          bill_address2: "",
          bill_city: "",
          bill_zipcode: "",
          bill_email: "",
          bill_phone_country_code: "",
          bill_phone: "",
          bill_alternate_contactno: null,
          bill_alternate_email: null,
        },
        card_info: {
          card_no: "",
          card_name: "",
          card_expiry: "",
          card_cvc: "",
        },
      },

      getCountryname: "",
      focusTop: 0,
      fareRulesApi:"",
      pointofsale:"",
      portal_domain:"",
      logged_email:"",
      logged_in:false,
    };
  },
  methods: {
    // filter: function (evt) {
    //   evt = evt ? evt : window.event;
    //   let expect = evt.target.value.toString() + evt.key.toString();

    //   if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
    //     evt.preventDefault();
    //   } else {
    //     return true;
    //   }
    // },
    identifyCard: function () {

      let getCarddetails = CardValidator.number(this.billingDetails.cardnumber);

      // console.log(getCarddetails, 'getCarddetailsgetCarddetailsgetCarddetails')

      if(getCarddetails.card){
        this.cvvCode = getCarddetails.card.code.name
        console.log(this.cvvCode,'cvvCodeee')
      }

      if (getCarddetails.card) {
        let gaps = getCarddetails.card.gaps;
        let cardNumberWithoutSpaces = this.billingDetails.cardnumber.replace(/\s/g, '');
        let formattedCardNumber = '';

        console.log(this.billingDetails.cardnumber, 'cardNumberWithoutSpacescardNumberWithoutSpaces');
        for (let i = 0; i < cardNumberWithoutSpaces.length; i++) {
          // console.log(i, 'dfsfsdfsd', this.billingDetails.cardnumber.length);
          if (gaps.includes(i)) {
            formattedCardNumber += ' ';
          }
          formattedCardNumber += cardNumberWithoutSpaces[i];
        }
        this.billingDetails.cardnumber = formattedCardNumber;
      }
      // Space Script End

    },


    showImage(cardType) {
      let niceType = this.result?.card?.niceType;

      // console.log(niceType,'niceTypeniceTypeniceType...1')

      if(niceType){
        if (niceType.toLowerCase().replace(/\s+/g, '-') === cardType) {
          // console.log(niceType.toLowerCase().replace(/\s+/g, '-'),'working....1',cardType);
          return true;
        } else {
          // console.log(niceType.toLowerCase().replace(/\s+/g, '-'),'working....2',cardType);
          return false;
        }
      }
    },


    formatCardnumber: function (evt) {
      // evt = evt ? evt : window.event;
      evt = evt || window.event;
      const key = evt.key;

      let cardNumber = this.billingDetails.cardnumber.replace(/\D/g, "");
      let getCarddetails = CardValidator.number(cardNumber);

      console.log(getCarddetails,'preventDefaultpreventDefault',this.billingDetails.cardnumber);

      if (getCarddetails.card) {
        let getCardlength = getCarddetails.card.lengths;

        if (getCardlength.includes(cardNumber.length)) {
          evt.preventDefault();
        }
      }

      if (!/^[0-9/]*$/.test(key)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    filtercardName: function (evt) {
      evt = evt || window.event;
      const char = String.fromCharCode(evt.which || evt.keyCode);

      // Allow only letters (a-z, A-Z) and spaces
      if (!/^[a-zA-Z\s]*$/.test(char)) {
        evt.preventDefault();
        return;
      }
    },

    filterExpdate: function (evt) {
      evt = evt || window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      // Allow only numbers and the '/' character
      if (!/^[0-9/]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    filterNumber: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    filterfullname: function (evt) {
      evt = evt || window.event;
      const char = String.fromCharCode(evt.which || evt.keyCode);

      // Allow only letters (a-z, A-Z) and spaces
      if (!/^[a-zA-Z\s]*$/.test(char)) {
        evt.preventDefault();
        return;
      }
    },

    filterCity: function (evt) {
      evt = evt || window.event;
      const char = String.fromCharCode(evt.which || evt.keyCode);

      // Allow only letters (a-z, A-Z) and spaces
      if (!/^[a-zA-Z\s]*$/.test(char)) {
        evt.preventDefault();
        return;
      }
    },

    filterPostalcode: function (evt) {
      evt = evt || window.event;
      const key = evt.key;

      if (!/^[a-zA-Z0-9]*$/.test(key)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    focusNext(e) {
      const inputs = Array.from(
        e.target.form.querySelectorAll(
          "v-text-field, input, select, textarea, button"
        )
      );
      const index = inputs.indexOf(e.target);

      if (index < inputs.length - 1) {
        inputs[index + 1].focus();
        e.preventDefault();
      }
    },

    handleNumericInput() {
      this.billingDetails.phonenumber = this.billingDetails.phonenumber.replace(
        /\D/g,
        ""
      );
    },
    handleNumericInput1() {
      this.billingDetails.alterPhone = this.billingDetails.alterPhone.replace(
        /\D/g,
        ""
      );
    },
    handleCountryChanged(countryCode) {
      this.countryCode = "+" + countryCode.dialCode;
      console.log("Selected Country Code:", this.countryCode);
    },
    formatExpireDate(event) {
      if (
        this.billingDetails.ExpireDate.length <= 2 &&
        event.inputType === "deleteContentBackward"
      ) {
        this.billingDetails.ExpireDate = this.billingDetails.ExpireDate.slice(
          0,
          3
        );
      } else if (this.billingDetails.ExpireDate.length === 2) {
        this.billingDetails.ExpireDate += "/";
      }
    },

    handleInput(e) {
      if (this.billingDetails.ExpireDate.length === 5 && e.keyCode !== 8) {
        e.preventDefault();
      }
    },

    // isMMYYFormat(value) {
    //     const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    //     return regex.test(value);
    // },

    retfligtTime(data) {
      // console.log(data, 'ppppppppp')
      let $event = data;
      let layoverTime = [];
      for (let i = 0; i <= $event.length - 1; i++) {
        // console.log($event[i], 'data.length - 1')

        // const currentIndex = i;
        // const nextIndex = (i + 1) % data.length;

        const time1 = new Date(
          `${data[i].Arrival.Date}T${data[i].Arrival.Time}`
        );
        const time2 = new Date(
          `${data[i].Departure.Date}T${data[i].Departure.Time}`
        );

        const diffInMilliseconds = Math.abs(time2 - time1);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        layoverTime.push(
          `${hours.toString().padStart(2, "0")} h:${minutes
            .toString()
            .padStart(2, "0")} min`
        );
      }
      let totalMinutes = layoverTime.reduce((total, time) => {
        let [hours, minutes] = time.match(/\d+/g).map(Number);
        return total + (hours * 60 + minutes);
      }, 0);

      let totalHours = Math.floor(totalMinutes / 60);
      let remainingMinutes = totalMinutes % 60;

      let combinedLayoverTime = `${totalHours
        .toString()
        .padStart(2, "0")} h:${remainingMinutes
        .toString()
        .padStart(2, "0")} min`;
      return combinedLayoverTime;
    },

    depfligtTime(data) {
      // console.log(data, 'ppppppppp')
      let $event = data;
      let layoverTime = [];
      for (let i = 0; i <= $event.length - 1; i++) {
        // console.log($event[i], 'data.length - 1')

        const time1 = new Date(
          `${data[i].Arrival.Date}T${data[i].Arrival.Time}`
        );
        const time2 = new Date(
          `${data[i].Departure.Date}T${data[i].Departure.Time}`
        );

        const diffInMilliseconds = Math.abs(time2 - time1);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        layoverTime.push(
          `${hours.toString().padStart(2, "0")} h:${minutes
            .toString()
            .padStart(2, "0")} min`
        );
      }
      // console.log(layoverTime, 'layoverTimelayoverTimelayoverTimelayoverTime')
      let totalMinutes = layoverTime.reduce((total, time) => {
        let [hours, minutes] = time.match(/\d+/g).map(Number);
        return total + (hours * 60 + minutes);
      }, 0);

      let totalHours = Math.floor(totalMinutes / 60);
      let remainingMinutes = totalMinutes % 60;

      let combinedLayoverTime = `${totalHours
        .toString()
        .padStart(2, "0")} h:${remainingMinutes
        .toString()
        .padStart(2, "0")} min`;
      return combinedLayoverTime;
    },
    closeBaggageRules(){
      this.tab = 'tab-1'
      // document.documentElement.style.overflow = 'scroll';
      document.body.style.overflow = "auto";
      document.body.style.position = "static";
    },

    getlogo(data) {
      console.log(data, "ppppppppppppp");
      this.logo = [];
      for (let i = 0; i < data.length; i++) {
        if (!this.logo.includes(data[i].MarketingCarrier.logo)) {
          this.logo.push(data[i].MarketingCarrier.logo);
          break;
        }
      }
      // console.log(this.logo,'logologologologo')
    },
    getlogo1(data) {
      console.log(data, "ppppppppppppp7777777777777");
      this.logo1 = [];
      for (let i = 0; i < data.length; i++) {
        if (!this.logo1.includes(data[i].MarketingCarrier.logo)) {
          this.logo1.push(data[i].MarketingCarrier.logo);
          break;
        }
      }
      // console.log(this.logo,'logologologologo')
    },
    getData() {
      let localData = JSON.parse(localStorage.getItem("rountData"));
      console.log(localData,'localDataStorage')
      this.fromdate = moment(localData.dedate).format("ddd, MMM D, YYYY");
      this.todate = moment(localData.redate).format("ddd, MMM D, YYYY");
      this.city = localData.city;

      if (localData.from) {
        this.from = localData.from.split(" ");
        this.from.forEach((v, i) => {
          if (i !== this.from.length - 1) {
            this.fromname = v;
            console.log(v, this.fromname, "tdtdtddtdttdd......");
          }
        });
        this.fromcode = this.from[this.from.length - 1]
          .replace("(", "")
          .replace(")", "");
      }

      if (localData.to) {
        this.to = localData.to.split(" ");
        this.to.forEach((v, i) => {
          if (i !== this.to.length - 1) {
            this.toname = v;
            console.log(v, this.toname, "ttsdtsdyusdsdsjd.....");
          }
        });

        this.tocode = this.to[this.to.length - 1]
          .replace("(", "")
          .replace(")", "");
      }

      // ------------->Check this Function<--------------
      if (localData.from.name) {
        this.from = localData.from.name.split(" ");
        this.from.forEach((v, i) => {
          if (i !== this.from.length - 1) {
            this.fromname += v;
          }
        });

        this.fromcode = this.from[this.from.length - 1]
          .replace("(", "")
          .replace(")", "");
      }

      if (localData.to.name) {
        this.to = localData.to.name.split(" ");
        this.tocode = this.to[this.to.length - 1]
          .replace("(", "")
          .replace(")", "");
      } else {
        this.to = localData.to.split(" ");
        this.tocode = this.to[this.to.length - 1]
          .replace("(", "")
          .replace(")", "");
      }
      // -------------><--------------

      // console.log(this.fromcode, " this.from");
      // console.log(this.tocode, "  this.to");
      // console.log(localData, "localData");
    },

    getadult() {
      this.getData();

      this.rountresult.forEach((v) => {
        console.log(v, "ddsdjvsdb");
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    onewayData() {
      this.getData();
      this.onewayresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    getprice1() {
      let priceData = [];
      let rount = JSON.parse(localStorage.getItem("itineryData"));
      let oneway = JSON.parse(localStorage.getItem("onewayItineyData"));
      if (rount) {
        priceData = rount;
      }
      if (oneway) {
        priceData = oneway;
      }
      this.basePrice = priceData.price;
      this.taxPrice = priceData.Taxprice;
      // console.log(priceData, "priceData");
    },

    getprice(res) {
      return (res.price + res.Taxprice).toFixed(2);
    },

    getdepseat(data) {
      // console.log(data,'ppppppppppppppppppppppp')
      return data[0].Seat;
    },

    getretseat(data) {
      return data[0].Seat;
    },

    getname($event) {
      return $event[0].MarketingCarrier.Name;
    },

    getDate($event) {
      return moment($event[0].Departure.Date).format("MMM DD");
    },
    getAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getDepAirportName($event) {
      return $event[0].Departure.AirportName;
    },

    getReturnDepAirName($event) {
      return $event[0].Arrival.AirportName;
    },

    getArrAirportName($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },

    getReturnArrAirName($event) {
      return $event[0].Arrival.AirportName;
    },

    getDepStops($event) {
      return $event.length - 1;
    },

    getReturnStops($event) {
      return $event.length - 1;
    },

    // getDep1($event) {
    //     return $event[0].Departure.AirportCode;
    // },

    getarrivaldata($event) {
      return $event[0].Arrival.AirportName;
    },
    getreturndata($event) {
      return $event[0].Arrival.AirportName;
    },
    getarrtime($event) {
      return $event[0].Arrival.Time;
    },
    getreturntime($event) {
      return $event[0].Arrival.Time;
    },
    get1arrtime($event) {
      // console.log($event,index,'$event')

      return $event[$event.length - 1].Arrival.AirportName;
    },
    get1returntime($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },

    get2arrtime($event) {
      return $event[$event.length - 1].Arrival.Time;
    },

    get2returntime($event) {
      return $event[$event.length - 1].Arrival.Time;
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },
    getArrivaldate($event) {
      return moment($event[$event.length - 1].Arrival.Date).format("MMM DD");
    },

    getArrivalCode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getreturnname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getreturnNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getreturnid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getreturnDate($event) {
      return moment($event[0].Departure.Date).format("MMM DD");
    },
    getdeparDate($event) {
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdated($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated1($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated2($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getreturnAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getreturnTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getreturnArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },
    getreturnArrivaldate($event) {
      return moment($event[$event.length - 1].Arrival.Date).format("MMM DD");
    },
    getreturnArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        this.topSticky = "5%";
      } else {
        this.scrolled = false;
        this.topSticky = "26%";
      }

      this.lastPosition = window.scrollY;
    },

    getRandomChars(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * this.strResult.length);
        result += this.strResult[randomIndex];
      }
      console.log(result, "result");
      return result;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    generateBookingRefId() {
      const randomChars1 = this.getRandomChars(3);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(3);

      this.booking_refId = randomChars1 + randomNum + randomChars2;
      console.log(this.booking_refId, "this.booking_refId ");
    },

    generateBookingRefId1() {
      const randomChars1 = this.getRandomChars(6);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(6);

      this.booking_Id = randomChars1 + randomNum + randomChars2;
      console.log(this.booking_Id, "this.booking_Id");
    },

    multiData() {
      let res = this.local_id.split("-");
      let res1 = res[res.length - 1];
      let data_pass = [];
      data_pass = JSON.parse(localStorage.getItem(`${"pass"}-${res1}`));

      this.adultLength = data_pass.adult;
      this.childLength = data_pass.child;
      this.infLength = data_pass.inf;
      this.adult = data_pass.adult;
      this.child = data_pass.child;
      this.inf = data_pass.inf;
      console.log(data_pass, "data_pass");

      let totalpass = [];
      totalpass = this.adultLength + this.childLength + this.infLength;
      console.log(totalpass, "totalpass");

      this.multiresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
      });
    },
    passengerDetail() {
      this.passDeatail = JSON.parse(localStorage.getItem("TravelerDetails"));
      console.log(this.adult,this.passDeatail, "this.adult this.adult ");

      for (let i = 0; i < this.passDeatail.adultDatas.length; i++) {
        if (i < this.adult) {
          this.adultDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Adult",
            seat_requested:[]
          });
        }
      }

      this.adultDetailData.forEach((a, i) => {
        this.passDeatail.adultDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
            a.seat_requested = v.seat_requested;
          }
        });
      });

      console.log(this.adultDetailData,'this.adultDetailDatathis.adultDetailData')

      for (let i = 0; i < this.passDeatail.childDatas.length; i++) {
        if (i < this.child) {
          this.childDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Child",
            seat_requested:[]
          });
        }
      }

      this.childDetailData.forEach((a, i) => {
        this.passDeatail.childDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
            a.seat_requested = v.seat_requested;
          }
        });
      });

      for (let i = 0; i < this.passDeatail.infrantDatas.length; i++) {
        if (i < this.inf) {
          this.infDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Infant",
            seat_requested:[]
          });
        }
      }

      this.infDetailData.forEach((a, i) => {
        this.passDeatail.infrantDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
            a.seat_requested = v.seat_requested;
          }
        });
      });

      this.totalPassData = [
        ...this.adultDetailData,
        ...this.childDetailData,
        ...this.infDetailData,
      ];

      console.log(this.totalPassData, "this.totalPassDatathis.totalPassData");
    },

    userbook() {
      let Travelcontact = JSON.parse(localStorage.getItem("Travelcontact"));
      console.log(Travelcontact, "Travelcontact");
      if (this.booking_refId && this.booking_Id) {
        this.userBooking.booking_ref_id = this.booking_refId;
        this.userBooking.hit_id = `${this.pointofsale}-${this.booking_Id}`;
      }
      this.userBooking.whatsapp_opted = Travelcontact.contactCheck;
      this.userBooking.newsletter_subscribe = Travelcontact.couponsCheck;
      this.userBooking.portal_id = this.portalId
      this.userBooking.portal_domain = this.portal_domain
      this.userBooking.logged_in = this.logged_in
      this.userBooking.adult = this.adult;
      this.userBooking.child = this.child;
      this.userBooking.paxInfant = this.inf;

      this.userBooking.pax_contact.pax_phone = this.paxcontact[0].number;
      this.userBooking.pax_contact.pax_email = this.paxcontact[0].email;
      let data = this.paxcontact[0].code;
      let countryCode = data.split("(").pop().replace(")", "");
      this.userBooking.pax_contact.countrycode = countryCode;
      this.userBooking.card_info.card_name = this.billingDetails.cardholdername;
      this.userBooking.card_info.card_no = this.billingDetails.cardnumber;
      this.userBooking.card_info.card_cvc = this.billingDetails.cvvnumber;
      this.userBooking.card_info.card_expiry = this.billingDetails.ExpireDate;

      this.userBooking.bill_info.bill_fullname = this.billingDetails.name;
      this.userBooking.bill_info.bill_Country = this.billingDetails.country;
      this.userBooking.bill_info.bill_state = this.billingDetails.state;
      this.userBooking.bill_info.bill_address1 = this.billingDetails.address1;
      this.userBooking.bill_info.bill_address2 = this.billingDetails.address2;
      this.userBooking.bill_info.bill_city = this.billingDetails.city;
      this.userBooking.bill_info.bill_zipcode = this.billingDetails.zipcode;
      this.userBooking.bill_info.bill_email = this.billingDetails.email;
      this.userBooking.bill_info.bill_phone = this.billingDetails.phonenumber;
      this.userBooking.bill_info.bill_alternate_contactno =
        this.billingDetails.phonenumber1;
      this.userBooking.bill_info.bill_alternate_email =
        this.billingDetails.email1;
      let data1 = this.billingDetails.coutyCode;
      let countryCode1 = data1.split("(").pop().replace(")", "");
      this.userBooking.bill_info.bill_phone_country_code = countryCode1;

      if(this.logged_email){
        this.userBooking.logged_email = this.logged_email
      }

      if (this.rountresult.length > 0) {
        this.userBooking.trip_type = "return";
        this.rountresult.forEach((s) => {
          console.log(s, "djcvbddhjbad");
          this.userBooking.offer_id = s.selectId;
          this.userBooking.pricingid = s.pricingid;
          this.userBooking.payment_enable = s.paymentgateway;
          this.userBooking.shopping_response_id = s.ShoppingResponseId;
          this.userBooking.pointofsale = s.currency.split("$")[0];
          this.userBooking.search_cabin_type = s.cabinClass;
        });
      }

      if (this.multiresult.length > 0) {
        this.userBooking.trip_type = "multi";
        this.multiresult.forEach((s) => {
          this.userBooking.payment_enable = s.paymentgateway;
          this.userBooking.offer_id = s.selectId;
          this.userBooking.pricingid = s.pricingid;
          this.userBooking.shopping_response_id = s.ShoppingResponseId;
          this.userBooking.pointofsale = s.currency.split("$")[0];
          this.userBooking.search_cabin_type = s.cabinClass;
        });
      }

      if (this.onewayresult.length > 0) {
        this.userBooking.trip_type = "oneway";
        this.onewayresult.forEach((s) => {
          this.userBooking.offer_id = s.selectId;
          this.userBooking.pricingid = s.pricingid;
          this.userBooking.payment_enable = s.paymentgateway;
          this.userBooking.shopping_response_id = s.ShoppingResponseId;
          this.userBooking.pointofsale = s.currency.split("$")[0];
          this.userBooking.search_cabin_type = s.cabinClass;
        });
      }

      this.userBooking.pax_info = [];

      this.adultDetailData.forEach((l) => {
          this.userBooking.pax_info.push(l);
      });

      this.childDetailData.forEach((l) => {
          this.userBooking.pax_info.push(l);
      });

      this.infDetailData.forEach((l) => {
          this.userBooking.pax_info.push(l);
      });
      
      console.log(this.userBooking.pax_info,'this.userBooking.pax_info..11')
    },

    getConfig() {
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {
        this.portalId = getConfigData1.payload.portal_configuration.portal_id;
        let getHref = this.$route.href;
        this.metaCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;

        this.fareRulesApi = getConfigData1.payload.portal_configuration.API_endpoints.farerules;
        this.pointofsale = getConfigData1.payload.portal_configuration.pointofsale;
        // let hostname = window.location.hostname;
        this.portal_domain = getConfigData1.payload.portal_configuration.portal_url.replace("https://","");

        if (getHref.includes("env")) {
          this.portalbookingUrl =
            getConfigData1.payload.portal_configuration.API_endpoints.meta_booking;
        } else {
          this.portalbookingUrl =
            getConfigData1.payload.portal_configuration.API_endpoints.booking;
        }
      }
    },
    getFareRules() {
      this.fareLoader = true;

      window.scrollTo(0,0)
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";

      let fareRequest = {
        request_type: "farerules",
        shopping_response_id: this.shoppingid,
        offer_id: this.selectId,
        portal_id: this.portalId,
        portal_domain: this.portal_domain
      };
      console.log(fareRequest, "fareRequestssssss");
      axios
        .get(this.fareRulesApi, {
          headers: {
            "Content-Type": "text/html",
          },
          params: fareRequest,
        })
        .then((response) => {
          this.fareRulesContent = response.data;
          this.fareLoader = false;
          // console.log(response.data, "fareRulesContentfareRulesContent.....")
        })

        .catch((error) => {
          console.log(error, "erroroor.....");
          this.fareLoader = false;
        });
    },

    submitForm() {
      let obj = {};
      this.generateBookingRefId1();
      this.generateBookingRefId();
      this.userbook();
      console.log(this.userBooking, "this.tocodethis.tocode");
      if (this.billingDetails.phonenumber) {
        this.datenumber = false;
      } else {
        this.datenumber = true;
      }
       if(!this.searchTerm){
          this.isCountryInvalid = true;
        }
        else{
          this.isCountryInvalid = false;
        }

      if (this.valid && this.billingDetails.phonenumber) {
        this.dataloader = true;
        this.showConfirmPage = false;
        // this.dataloader = false;
        this.datenumber = false;
        if (this.portal) {
          this.userBooking.source = "env=cert";
        } else {
          this.userBooking.source = "portal";
        }
       

        console.log(
          this.userBooking,
          this.portalbookingUrl,
          "this.userBookingthis.userBookingthis.userBooking this.portalbookingUrl"
        );

        axios
          .post(this.portalbookingUrl, this.userBooking, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(
              response,
              "responseresponseresponseidd312513524242442."
            );

            obj = {
              booking_Id: response.data.booking_details.booking_id,
              last_name: response.data.booking_details.pax_info[0].lastname,
              pnr: response.data.booking_details.pnr,
            };
            

            let booking_details = JSON.stringify(obj);
            console.log(obj, booking_details, "pvacatCSAACFAfc");
            if (this.portal) {
              let uniDataId = `${"env"}-${this.local_id}`;
              localStorage.setItem(
                `${"env"}-${this.local_id}`,
                JSON.stringify(this.portalResData)
              );

              this.$router.push({
                path: "/bookingView",
                query: { pass_id: uniDataId, booking_id: booking_details },
              });
              this.dataloader = false;
            } else {
              if (this.local_id) {
                // setTimeout(() => {
                this.$router.push({
                  path: "/bookingView",
                  query: {
                    pass_id: this.local_id,
                    booking_id: booking_details,
                  },
                });
                // }, 2000);
              }
              if (this.Localoneway_id) {
                // setTimeout(() => {
                this.$router.push({
                  path: "/bookingView",
                  query: {
                    pass_uid: this.Localoneway_id,
                    booking_id: booking_details,
                  },
                });
                // }, 2000);
              }
              this.dataloader = false;
              
              // else {
              //   alert("routing error....");

              // }
            }

            console.log(
              response,
              "userBookinguserBookinguserBookinguserBooking"
            );
          })
          .catch((error) => {
            this.dataloader = false;
            this.showConfirmPage = true;
            setTimeout(() => {
              this.dialog3 = true;
              
            }, 7000);

            console.log(error, "error mision");

            this.dialog3 = true;
          });
      } else {
        // this.datenumber=true
        //   alert(
        //     "Airlines unable to confirm seats. Please try again later or book another itinerary"
        //   );
      }
    },
    // countryData() {
      // countries.forEach((v) => {
      //   this.countriesData.push(v);
      // });
      // const majorCountries = ["Canada", "India", "United States", "United Kingdom"];
      // const majorCountries = majorCountries;
      // console.log(majorCountries,'majorCountriesmajorCountries')
  //     const majorCountryNames = majorCountries.map(country => country.name);
  // const major = majorCountryNames.filter(country => majorCountries.includes(country.name));
  // const others = countries.filter(country => !majorCountries.includes(country.name));

  // this.countriesData = [];
  // this.countriesData.push({ label: 'Major Countries', isLabel: true });
  // major.forEach((v) => {
  //   this.countriesData.push(v);
  // });

  // // Add separator
  // this.countriesData.push({ type: 'separator' });

  // // Add other countries
  // others.forEach((v) => {
  //   this.countriesData.push(v);
  // });
  //   },'
  countryData() {
    const majorCountryNames = majorCountries.map(country => country.name);
    
    const major = countries.filter(country => majorCountryNames.includes(country.name));
    const others = countries.filter(country => !majorCountryNames.includes(country.name));

    this.countriesData = [];
    
  //   // Add Major Countries label
  //   // this.countriesData.push({ type: 'label', label: 'Major Countries' });

  //   // Add Major Countries
  //   // major.forEach((country) => {
  //   //   this.countriesData.push(country);
  //   // });

  //   // // Add separator
  //   // this.countriesData.push({ type: 'separator' });

  //   // // Add Other Countries
  //   // others.forEach((country) => {
  //   //   this.countriesData.push(country);
  //   // });
       this.countriesData = [
         ...major,
          { type: 'separator', id: 'separator' },
         ...others
       ];
      // console.log(this.countriesData,'countriesDatacountriesData....1')
 },

    selectCountry(countryName) {
      this.billingDetails.country = countryName;
      this.$refs.countryInput.isFocused = false;
      // this.searchTerm = countryName;
      // this.dropdownVisible = false;
      // this.isCountryInvalid = false;
    },
     handleClickOutside() {
      // If the click is outside of the dropdown, close it
      // if (!this.$refs.dropdown.contains(event.target)) {
        this.dropdownVisible = false;
      // }
    },
     getperpersonPrice(getdata){

      if (getdata.adultcount) {
        let getAdtperfare = parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getAdtperfare, 'getadultTotBasegetadultTotBase...2');
        return getAdtperfare.toFixed(2);
      }

      if (getdata.childcount) {
         let getChdperfare = parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
         console.log(getChdperfare, 'getadultTotBasegetadultTotBase...3');
         return getChdperfare.toFixed(2);
       }

      if (getdata.infcount) {
          let getInfperfare = parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
          console.log(getInfperfare, 'getadultTotBasegetadultTotBase...4');
          return getInfperfare.toFixed(2);
      }
    },
    translatePaxType(data){
      if(data == "Adult"){
        return this.$t("formsContents.options.adult")
      }
      else if(data == "Child"){
        return this.$t("formsContents.options.child")
      }
      else if(data == "Infant"){
        return this.$t("formsContents.options.infant")
      }
    },
  },

  computed: {
    getDataResult() {
      if (this.rountresult && this.rountresult.length > 0) {
        return this.rountresult;
      } else if (this.onewayresult && this.onewayresult.length > 0) {
        return this.onewayresult;
      } else if (this.multiresult && this.multiresult.length > 0) {
        return this.multiresult;
      }

      return [];
    },
    dotColor() {
      return this.itineraryInfo ? "primary" : "success";
    },
    timeTextcolor() {
      return this.itineraryInfo ? "blue" : "black";
    },
    filteredCardNumber() {
      return this.billingDetails.cardnumber.replace(/\D/g, "");
    },
    // filteredMajorCountries() {
    //   return this.countriesData.filter(country =>
    //     majorCountries.includes(country.name) &&
    //     country.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    //   );
    // },

    // filteredCountries() {
    //   const searchTerm = this.searchTerm.toLowerCase();
    //   return this.countriesData.filter(country => {
    //     if (country.type === 'separator') return true;
    //     return country.name.toLowerCase().includes(searchTerm);
    //   });
    // },

    //     expDate() {
    //   return [
    //     (v) => !!v || 'This field is required',
    //     (v) => this.isMMYYFormat(v) || 'Invalid format (MM / YY)',
    //   ];
    // },
    result: function () {
      return CardValidator.number(this.billingDetails.cardnumber);
    },
    appendIcon() {
      return this.result.isValid ? 'mdi-check-underline' : 'mdi-credit-card-outline'
    },
    iconColor() {
      if (this.billingDetails.cardnumber == '') return ''
      return this.result.isValid ? 'green' : 'red'
    },
  },
  watch: {
    "$i18n.locale": {
      handler: function () {
        this.translatePaxType();
      },
      deep: true,
    },
    "billingDetails.country"(newVal) {
      let result = [];
      this.countriesData.forEach((v) => {
        if (v.name == newVal) {
          result = v.code;
        }
      });
      Object.keys(state).forEach((c) => {
        if (c == result) {
          this.stateData = state[c];
          // console.log(state[c], 'pppppppppppppppppppppppppp');
        }
      });

      this.getCountryname = newVal;

      country1.forEach((v) => {
        let obj = {
          name: `${v.name}(${v.dial_code})`,
          code: v.code,
        };
        this.countrycode.push(obj);

        if (v.name == this.getCountryname) {
          this.countrycode.find((item) => {
            this.billingDetails.coutyCode = item.name;
            // console.log(this.billingDetails.coutyCode, 'v.namev.namev.namev.name')
          });
        }
      });
    },

    "billingDetails.cvvnumber"(newValue) {
      if (newValue.length > 3) {
        this.billingDetails.cvvnumber = newValue.slice(0, 3);
        console.log('cvvCondition..1')
      }
      if(newValue.length > 3 && this.showImage('american-express')){
        this.billingDetails.cvvnumber = newValue.slice(0, 4);
        console.log('cvvCondition..2')
      }
    },
    "billingDetails.zipcode"(newValue) {
      if (newValue.length > 7) {
        this.billingDetails.zipcode = newValue.slice(0, 7);
      }
    },

    "billingDetails.phonenumber"(newValue) {
      if (newValue.length > 12) {
        this.billingDetails.phonenumber = newValue.slice(0, 12);
      }
    },
    "billingDetails.phonenumber1"(newValue) {
      if (newValue.length > 12) {
        this.billingDetails.phonenumber1 = newValue.slice(0, 12);
      }
    },
    // "billingDetails.cardnumber"(newValue) {
    //   if (newValue.length > 16) {
    //     this.billingDetails.cardnumber = newValue.slice(0, 16);
    //   }
    // },
     "billingDetails.cardnumber"(newValue) {

      if (!newValue) {
        this.cardNumber = false;
      }
     },
  },

  created() {

    let $useresult = JSON.parse(localStorage.getItem("loginuser"));
    if ($useresult) {
      this.logged_email = $useresult.user.email;
      this.logged_in = true
    }
    else {
      this.logged_in = false
    }

    this.focusTop = 0;
    console.log(this.focusTop, "this.focusTopthis.focusTopthis.focusTop");

    let $data = localStorage.getItem("delete_Id");

    if ($data) {
      this.Localoneway_id = this.$route.query.pass_id1;
      this.local_id = this.$route.query.pass_id;

      // localStorage.removeItem(`${this.Localoneway_id}`);
      // localStorage.removeItem(`${this.local_id}`);
    }

    // let $data = localStorage.getItem("loader")
    // if ($data == "true") {
    //   this.$router.push('/')
    // }

    let $paxcontact = localStorage.getItem("passengerContact");
    // console.log($paxcontact, '$paxcontact')
    this.paxcontact = JSON.parse($paxcontact);
    // console.log(this.paxcontact[0].number, "ppppp");
    this.billingDetails.phonenumber = this.paxcontact[0].number,
    this.billingDetails.email = this.paxcontact[0].email,
    this.billingDetails.coutyCode = this.paxcontact[0].code,

    this.local_id = this.$route.query.pass_id;
    this.Localoneway_id = this.$route.query.pass_id1;

    console.log(
      this.Localoneway_id,
      "this.local_id this.local_idthis.Localoneway_id "
    );
    console.log(this.local_id, "this.local_id env ");
    this.getConfig();

    if (this.Localoneway_id) {
      let onewaydata_uid = [];
      onewaydata_uid = JSON.parse(
        localStorage.getItem(`${this.Localoneway_id}`)
      );
      console.log(onewaydata_uid,'oneway_uid')

      if (onewaydata_uid) {
        this.onewayresult.push(onewaydata_uid);
        this.onewayData();
        this.itineraryInfo1 = true;

        setTimeout(() => {
          this.dataloader = false;
          this.showConfirmPage = true;
        }, 3000);
      } else {
        this.dialog4 = true;
      }
    } else if (this.local_id) {
      if (this.local_id.includes("env")) {
        this.portal = true;
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));
        console.log(data_uid, "data_uiddata_uid");

        // if (!data_uid) {
        //   alert('Something went Wrong...')
        //   this.$router.push('/')
        // }
        console.log(data_uid, "data_uiddata_uiddata_uid");
        if (data_uid) {
          if (data_uid[0].Flight3 ? true : false) {
            this.multiresult = data_uid;
          } else if (data_uid[0].Return.length > 0 ? true : false) {
            this.rountresult = data_uid;
          } else {
            this.onewayresult = data_uid;
          }

          data_uid.forEach((v) => {
            this.local_id = v.selectId;
            this.currency = v.currency;
            let totalpass = [];

            this.adult = parseFloat(v.adult);
            this.child = parseFloat(v.child);
            this.inf = parseFloat(v.inf);

            console.log(
              this.adult,
              "this.adult",
              this.child,
              "this.child",
              this.inf,
              "this.inf"
            );

            totalpass =
              parseFloat(v.adult) + parseFloat(v.child) + parseFloat(v.inf);
            console.log(totalpass, "totalpass");

            this.totalTaxPassanger = parseFloat(
              parseFloat(v.Taxprice) * totalpass
            ).toFixed(2);
            this.totalbasePassanger = parseFloat(
              parseFloat(v.Baseprice) * totalpass
            ).toFixed(2);
            this.totalAmountpassenger = parseFloat(
              parseFloat(this.totalbasePassanger) +
                parseFloat(this.totalTaxPassanger)
            ).toFixed(2);

            console.log(data_uid, "data_uiddata_uiddata_uid");
            console.log(v, "basePassangerbasePassangerbasePassanger");
            console.log(
              this.totalAmountpassenger,
              this.totalbasePassanger,
              this.totalTaxPassanger,
              "this.totalbasePassangerthis.totalbasePassanger"
            );
          });
          setTimeout(() => {
            this.dataloader = false;
            this.showConfirmPage = true;
          }, 3000);
        } else {
          console.log(this.dialog4, "this.dialog4......2");
          this.dialog4 = true;
          // console.log("Something went Wrong....1")
          // alert("Something went Wrong...");
          // this.$router.push("/");
        }
      } else if (this.local_id.includes("multi")) {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));

        if (data_uid) {
          this.multiresult.push(data_uid);
          this.multiData();
          console.log(data_uid, "multicity");
          setTimeout(() => {
            this.dataloader = false;
            this.showConfirmPage = true;
          }, 3000);
        } else {
          this.dialog4 = true;
          console.log("Something went Wrong....2");
          // alert("Something went Wrong...");
          // this.$router.push("/");
        }
      } else if (this.local_id) {
        let data_uid1 = [];
        data_uid1 = JSON.parse(localStorage.getItem(`${this.local_id}`));
        console.log(data_uid1, "data_uid1data_uid1");

        if (data_uid1) {
          this.rountresult.push(data_uid1);
          this.getadult();
          this.itineraryInfo = true;
          setTimeout(() => {
            this.dataloader = false;
            this.showConfirmPage = true;
          }, 3000);
        } else {
          this.dialog4 = true;
          console.log("Something went Wrong....3");
          // alert("Something went Wrong...");
          // this.$router.push("/");
        }
      }
    } else {
      this.dialog4 = true;
      console.log(this.dialog4, "this.dialog4......3");
      // alert("Something went Wrong...");
      // this.$router.push("/");
    }
     if (this.rountresult.length > 0) {
          this.shoppingid = this.rountresult[0].ShoppingResponseId;
          this.selectId = this.rountresult[0].selectId;
        } else if (this.onewayresult.length > 0) {
          this.shoppingid = this.onewayresult[0].ShoppingResponseId;
          this.selectId = this.onewayresult[0].selectId;
        } else if (this.multiresult.length > 0) {
          this.shoppingid = this.multiresult[0].ShoppingResponseId;
          this.selectId = this.multiresult[0].selectId;
        }
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  mounted() {
    this.passengerDetail();
    country1.forEach((v) => {
      // v.name = this.getCountryname
      // console.log(v.name,'v.namev.namev.namev.name')
      let obj = {
        name: `${v.name}(${v.dial_code})`,
        code: v.code,
      };
      this.countrycode.push(obj);
    });
    console.log(this.$route.params.id, "  this.$route.params.id");
    this.countryData();
    document.addEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
/* * {
  font-family: system-ui, "-apple-system", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", sans-serif !important;
} */
@import '@/assets/style.css';
.v-card {
  overflow: unset;
  z-index: auto;
  border-radius: 4px !important;
}

.v-tool {
  height: 50px;
  display: flex;
  justify-content: center;
}

>>> .dp__action_select {
  color: white !important;
  padding: 0px 7px;
}

>>> .dp__input {
  height: 40px;
  border-color: darkgray;
}

>>> .v-input__control {
  height: 40px;
}

>>> .v-field__field {
  height: 40px;
}

>>> .v-field__input {
  padding-top: 0 !important;
}

.vue-tel-input {
  height: 40px;
}

>>> .alternate-Details .v-input__control {
  height: 0px;
}

>>> .alternate-Details .v-input__details {
  display: none;
}

>>> .alternate-Details .v-selection-control {
  --v-selection-control-size: 22px;
}

>>> .alternate-Details .v-selection-control-group--inline {
  justify-content: end;
}

>>> .alternate-Details .v-selection-control__wrapper {
  font-size: 12px;
  width: 22px;
}

>>> .alternate-Details .v-label--clickable {
  font-size: 14px;
}

>>> .country-field .v-field--variant-outlined input {
  top: 17% !important;
}

.travel-view {
  text-decoration: underline;
  color: darkblue;
  cursor: pointer;
  font-weight: 600;
}

>>> .v-toolbar__content,
.v-toolbar__extension {
  align-items: center;
  height: 30px !important;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  transition: inherit;
  width: 100%;
}

/* Timeline  */

>>> .v-timeline-divider__dot--size-large {
  height: 22px;
  width: 56px;
}

>>> .v-timeline-divider__inner-dot {
  border-radius: 15px;
}

>>> .v-timeline-divider__inner-dot span {
  font-size: 11px;
  font-weight: 600;
}

>>> .dp__menu_inner {
  padding: var(--dp-menu-padding);
  box-shadow: 0px 0px 4px 0px black;
  background-color: #f5f5f521;
}

/* Timeline End  */

/* >>>.v-card {
        position: inherit !important;
      } */

.f-size-16 {
  font-size: 16px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.card-table {
  width: 80% !important;
}

>>> .v-timeline--vertical.v-timeline {
  grid-row-gap: 3px;
  height: auto !important;
}

.fare-summery {
  position: sticky;
  width: 100%;
  z-index: 0;
  transition: top 0.5s;
}

>>> .v-selection-control .v-label {
  white-space: normal;
  word-break: normal;
  height: 100%;
  width: 100%;
}

.v-input--density-default {
  --v-input-control-height: 53px;
}

.v-selection-control--density-default {
  --v-selection-control-size: 30px;
}

.font-weight {
  font-weight: 500;
}

.timeline-vertical {
  display: none;
}

.res-icon {
  border: 1px solid #05386d;
  position: relative;
  top: 42px;
  width: 92%;
}

.res-icon1 {
  float: right;
  color: #05386d;
  font-size: 18px;
  position: relative;
  top: 28px;
}

.tot-price {
  font-size: 18px;
  font-weight: 600;
}

>>> .continue-section .v-btn__content {
  font-size: 16px;
  font-weight: 500;
}

>>> .continue-section .v-btn__content {
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (min-width: 481px) {
  .fare-show {
    display: none;
  }

  .continue-btn {
    display: none;
  }

  .terms-text {
    display: none;
  }
}
@media screen and (max-width:992px) {
  .fare-show {
    display: block;
  }

  .fare-summery {
    display: none;
  }

}

@media only screen and (max-width: 480px) {
  .aircode-name .f-size-16 {
    font-size: 12px;
  }

  .aircode-name .f-size-13 {
    font-size: 9px;
  }

  .airline-date .f-size-16 {
    font-size: 12px;
  }

  .airline-date .f-size-11 {
    font-size: 9px;
  }

  .card-space {
    margin: 2px;
  }

  .get-logo {
    width: 25px !important;
  }

  .air-name {
    font-size: 9px !important;
  }

  .air-id {
    font-size: 7px !important;
  }

  .airline-logo {
    width: 15px !important;
  }
  .cardValidation, .cardName, .cardExp, .cardCvv{
    width:100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .Timing-section .v-icon {
    font-size: 14 !important;
  }

  .Timing-section .f-size-14 {
    font-size: 11px !important;
  }

  .Timing-section .f-size-12 {
    font-size: 9 !important;
    line-height: normal !important;
  }

  /* .airline-logo {
    width: 20px !important;
  } */

  .timeline-horizontal {
    display: none;
  }

  .timeline-vertical {
    display: inline-grid;
    height: 420px;
  }

  .content-overflow {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .content-overflow table {
    width: 100%;
    min-width: 750px;
    /* Adjust this value according to your table's content */
  }

  .card-table {
    width: 100% !important;
  }

  .air-name {
    font-size: 12px;
  }

  .air-id {
    font-size: 9px;
  }
}

/* Scroll Bar Styles */

.content-overflow::-webkit-scrollbar {
  width: 20px;
}

.content-overflow::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 25px;
}

/* minimalist design */
.content-overflow::-webkit-scrollbar {
  width: 10px;
}

.content-overflow::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
}

.content-overflow::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

/* striped lines */
.content-overflow::-webkit-scrollbar {
  width: 20px;
}

.content-overflow::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}

/* animated  */
.content-overflow::-webkit-scrollbar {
  width: 8px !important;
}

.content-overflow::-webkit-scrollbar-track {
  background: darkblue;
}

.content-overflow::-webkit-scrollbar-thumb {
  background: lightgray;
}

>>> .v-autocomplete--single .v-field--variant-outlined input {
  top: 15%;
}
>>> .drawer-section .v-slide-group__content {
  display: flex;
  justify-content: center !important;
}

>>> .v-slide-group__content .v-btn {
  height: 45px !important;
}

>>> .v-slide-group__content .v-btn__content {
  font-size: 12px;
}

>>> .drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}
>>>.cancellationRules .cancellation_content__2apKn .FareRule h2{
  font-size:17px !important;
  font-weight:700 !important;
}
>>>.cancellationRules .cancellation_content__2apKn .FareRule p{
  overflow-wrap: break-word !important;
  /* inline-size: 58%; */
}
>>>.cancellationRules .cancellation_content__2apKn .FareRule ul li{
  overflow-wrap: break-word !important;
  /* inline-size: 60%; */
}
>>>.cancellationRules .cancellation_content__2apKn .FareRule form ul{
  height:100%;
}

>>> .drawer-section
  .v-slide-group__content
  .v-slide-group-item--active
  .v-btn__content {
  color: #002d5b;
}
>>> .v-tabs--density-default.v-tabs--stacked {
  --v-tabs-height: 58px;
}
>>> .v-tabs--fixed-tabs .v-slide-group__content > *:last-child,
.v-tabs--align-tabs-center .v-slide-group__content > *:last-child {
  margin-inline-end: inherit;
}
.offcanvas-title{
  color:white;
}
.card-image {
  height: 32px;
  width: 35px;
}

.card{
  max-width:39px !important;
}
>>>.card .v-field--appended{
  padding-inline-end:6px !important;
}

>>>.card_Number .v-field{
  height: 42px;
  padding-right:6px;
}
>>>.card_Number .v-field .v-field__input{
  padding-top: 5px;
  letter-spacing: 1px;
  font-weight: 500;
}
>>>.card_Number .v-field__field input::placeholder{
  letter-spacing: 0 !important;
}
>>>.card_Number .v-field__append-inner{
  padding-top: 3px;
  align-items: baseline;
}
.cardValidation{
  width:39%;
}
.cardName{
  width:28%;
}
.cardExp{
  width:19%;
}
.cardCvv{
  width:12%;
}
>>>.v-text-field .v-input__details{
  padding-inline:0px;
}
.invisible{
  display:none;
}
>>>.v-progress-circular{
  width:24px;
}
.dropdown {
  position: relative;
  width: 257px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.dropdown input {
  width: 100%;
  padding:8px 10px;
  font-size: 15px;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  color: #616161;
}
.dropdown input::placeholder{
  font-size:15px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.dropdown-content {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  width: auto;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-label {
  font-weight: bold;
  padding: 10px;
  background-color: #f7f7f7;
  text-transform: uppercase;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-separator {
  border: none;
  border-top: 2px solid #8d8c8c;
  margin: 5px 0;
}
.is-invalid {
  border-color: #b00020 !important;
}
.is-invalid .floating-label{
  color:#b00020;
}

.error-message {
  color: #b00020;
  font-size:12px;
  /* margin-top: 5px; */
}
.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border:1px solid darkgray;
  border-radius:5px;
  padding:0px 6px;
}
.rotate-icon {
  display: inline-block;
  transition: transform 0.3s ease;
  transform-origin: center; 
}

.rotate {
  transform: rotate(180deg);
}
.input-wrapper label {
  position: absolute;
  left: 12px;
  top: 7px;
  transition: 0.2s ease all;
  color: #aaa;
  font-size:16px;
  line-height:1.2;
  padding:3px;
}

input:focus + .floating-label,
.floating-label.active {
  top: -10px;
  left: 12px;
  font-size: 14px;
  color: #aaa; 
  background-color:white;
}
/* Scroll Bar Styles End */
</style>
