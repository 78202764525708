<template>
    <footer>
        <div style="background-color:#26225e; color: white" class="footer_content">
        <div class="container">
          <div class="row py-2 px-2 gy-3 m-0 d-flex align-center">
            <div class="col-md-2 px-0 col-xs-6 col-sm-2 m-0">
            <div class="footer-col-1" align="left">
              <v-img :src="portalLogo" style="height: 48px; filter: brightness(0) invert(1);" />
            </div>
          </div>
             
            <div class="col-md-1 col-xs-6 col-sm-1 px-0 text-center m-0" v-if="footMenu.includes('aboutus')">
            <h6 class="mb-0 mt-2">
              <a href="/aboutUs" target="_blank" style="color: white; text-decoration: underline;font-size:13px;">{{ $t("footerContents.aboutUs") }}</a>
            </h6>
          </div>
          <div class="col-lg-1 col-md-2 px-0 col-xs-6 col-sm-2 text-center m-0" v-if="footMenu.includes('contactus')">
            <h6 class="mb-0 mt-2">
              <a href="/contactPage" target="_blank" style="color: white;text-decoration:underline;font-size:13px;">
                {{ $t("footerContents.contactUs") }}</a>
            </h6>
          </div>
          <div class="col-lg-1 col-md-2 px-0 col-xs-6 col-sm-2 text-center m-0 ms-md-2 ms-sm-0" v-if="footMenu.includes('privacypolicy')">
            <h6 class="mb-0 mt-2">
              <router-link to="/page/privacy-policy" target="_blank" style="color: white;text-decoration:underline;font-size:13px;">{{ $t("footerContents.privacyPolicy") }}</router-link>
            </h6>
          </div>
          <div class="col-lg-1 col-md-2 px-0 col-xs-6 col-sm-2 text-center m-0 ms-md-3 ms-sm-0" v-if="footMenu.includes('termsofuse')">
            <h6 class="mb-0 mt-2">
              <router-link to="/page/terms-of-use" target="_blank" style="color: white;text-decoration:underline;font-size:13px;">
                {{ $t("footerContents.termsofUse") }}
              </router-link>
            </h6>
          </div>
          <div class="col-md-4 d-lg-block d-md-none d-sm-none">

          </div>
           <div class="col-lg-1 col-md-2 col-xs-6 col-sm-2 mt-2 px-0 d-flex flex-row justify-end align-center ms-sm-2" v-if="social_media">
            <a v-for="(data,index) of social_media" :key="index" target="_blank" class="btn-link btn-floating btn-lg text-dark me-2 social-icons"
              :href="data.url" role="button" data-mdb-ripple-color="dark">
              <v-icon size="22"> {{ data.icon_class }} </v-icon>
            </a>
          </div>
          </div>
        <div class="row p-3 pt-0 ps-0 gy-3 m-0 d-flex align-center justify-content-between">
         
          
           <div class="col-12 col-sm-3 col-md-2 col-lg-3 px-0 mt-2 pt-1 text-center">
              <p class="m-0" style="font-size: 13px;color:white;">{{ $t("footerContents.copyright") }}©{{ get_year() }}, {{ portLegaName }}. {{ $t("footerContents.allrights") }}.</p>
            </div>
            <div class="col-6 d-flex justify-center mt-2">
              <div class="row">
             <div class="col-sm-1 f-img px-0 mt-0">
            <v-img :src="require('@/assets/img/credit_cards/visa.png')"></v-img>
          </div>

          <div class="col-sm-1 f-img px-0 mt-0">
            <v-img :src="require('@/assets/img/credit_cards/mastercard.png')"></v-img>
          </div>
          <div class="col-sm-1 f-img px-0 mt-0">
            <v-img :src="require('@/assets/img/credit_cards/american_express.png')"></v-img>
          </div>
          <div class="col-sm-1 f-img px-0 mt-0">
            <v-img
              :src="require('@/assets/img/credit_cards/jcb.png')"
              style="height: 54px"
            ></v-img>
          </div>
          <div
            class="col-sm-1 f-img px-0 mt-0"
            style="position: relative;">
            <v-img :src="require('@/assets/img/credit_cards/diners_club.png')"></v-img>
          </div>
          <div class="col-sm-1 f-img px-0 mt-0">
            <v-img :src="require('@/assets/img/credit_cards/discover.png')"></v-img>
          </div>
          <!-- <div class="col-sm-1 f-img" style="width: 100px">
            <v-img :src="require('@/assets/img/credit_cards/aff.png')"></v-img>
          </div>
          <div class="col-sm-1 f-img mt-1">
            <v-img :src="require('@/assets/img/credit_cards/gpay.png')"></v-img>
          </div> -->
          </div>
          </div>
            <div class="col-md-2 col-sm-3 col-lg-3 mt-2 col-12 text-center visible" v-if="portAddress1 && portAddress2">
              <p class="m-0" style="font-size:12px;color:white;"><span>{{ portAddress1 }}</span>
                <span class="ms-2">{{ portAddress2 }}</span>
              </p>
            </div>
          <!-- <div class="col-md-2 mt-2 d-flex flex-row justify-start align-center" v-if="social_media">
            <a v-for="(data,index) of social_media" :key="index" target="_blank" class="btn-link btn-floating btn-lg text-dark me-2 social-icons"
              :href="data.url" role="button" data-mdb-ripple-color="dark">
              <v-icon size="25"> {{ data.icon_class }} </v-icon>
            </a>
          </div> -->
        </div>
        <!-- <div class="" :class="!portAddress1 && !portAddress2 && !portLegaName ? 'd-none' : ''">
        <div class="copyrightSection row m-0 py-2 d-flex justify-space-evenly flex-row align-center">
            <div class="col-md-12 col-sm-6 col-lg-6 col-12 px-5 text-lg-start text-center visible" :class="portAddress1 && portAddress2 ? 'visible' : 'invisible'">
              <p class="m-0" style="font-size:12px;color:white;"><span>{{ portAddress1 }}</span>
                <span class="ms-2">Email: {{ portAddress2 }}</span>
              </p>
            </div>
            
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 px-5 text-center text-lg-end">
            <p class="m-0" style="font-size: 13px;color:white;">{{ $t("footerContents.copyright") }}©{{ get_year() }}, {{ portLegaName }}. {{ $t("footerContents.allrights") }}.</p>
          </div>
        </div>
      </div> -->
  
      </div>
      </div>
    </footer>
  </template>
  
  
  <script>
  
  import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';
  
  
  
  export default {
    data() {
      return {
  
        portalLogo: "",
        getInstagram: "",
        getFacebook: "",
        getThreads:"",
        getLinkedIn:"",
        getYoutube:"",
  
        footerMenus: [],
        footMenu: "",
  
        phonePortal: '',
        emailPortal: '',
  
        portAddress1:"",
        portAddress2:"",
        portLegaName:"",
        social_media:[],
  
      }
    },
  
    methods: {
      get_year(){
        return new Date().getFullYear()
      },
  
      getConfig() {
  
        const getConfigData = getConfigDataFromLocalStorage();
  
        if (getConfigData) {
          this.portalLogo = getConfigData.payload.portal_configuration.logo_path

          let social_icons = getConfigData.payload.portal_configuration.social_icons
          if(social_icons){
            this.social_media = social_icons;
          }

  
          this.portAddress1 = getConfigData.payload.portal_configuration.portal_footer_address1
          this.portAddress2 = getConfigData.payload.portal_configuration.portal_footer_address2
          this.portLegaName = getConfigData.payload.portal_configuration.portal_legal_name
  
  
          this.phonePortal = getConfigData.payload.portal_configuration.phone
          this.emailPortal = getConfigData.payload.portal_configuration.email
  
          // let footMenu=""
          this.footMenu = getConfigData.payload.portal_configuration.menus.enabled.footer
          // this.footerMenus = footMenu.split(',')

          // this.portAddress2 = this.portAddress2.split(":")[1]
  
          // console.log(this.footMenu, 'fmfmfmfmfm...')
        }
      },
  
  
    },
  
  
    mounted() {
      // this.getConfig();
    },
  
    created() {
      this.getConfig();
      this.get_year();
    }
  }
  
  </script>
  
  
  
  
  
  
  
  
  
  <style scoped>
  /* footer */
  .social-icons {
    color: var(--primary-color) !important;
    padding: 0;
  }
  .social-icons{
    color:white;
  }
  .social-icons:hover{
    color:#26225e !important;
    background-color:white;
    padding:2px;
  }
  .social-icons a:visited{
    color:#05386d !important;
  }
  .fs-14 {
    font-size: 14px;
    list-style-type: none;
  }
  
  .fs-14 li {
    padding: 6px;
  }
  
  section {
    padding: 10px 5px;
  }
  
  .slide-card {
    border-radius: 10%;
  }
  
  .card-test {
    position: relative;
    padding: 10px 15px;
    border-radius: 8%;
    max-height: 500px;
  }
  
  .card-img {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -50%;
    right: 0;
    transform: translateY(-50%);
  }
  
  .radio {
    background-color: #e2e7ff;
    border-radius: 14px;
    color: #007bff;
    width: 120px;
    font-size: 15px !important;
    font-weight: 400px;
  }
  .copyrightSection{
    color:white !important;
  }
  .normal-radio {
    font-size: 14px !important;
    font-weight: 400px;
    height: 30px;
    width: 130px;
  }
  
  .cart-adult {
    padding: 2px 15px 15px 15px;
    width: 450px;
    cursor: pointer;
  }
  
  .adult {
    border: 1px solid lightgrey;
    border-radius: 5px;
    background-color: #f7f7f7;
  }
  
  .adult-plus {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #05386d;
  }
  
  .adult-title {
    font-size: 12px;
    font-weight: 600;
    color: #05386d;
  }
  
  .p-datepicker-group-container {
    background-color: #ffffff !important;
    width: 200px;
    padding: 5px 3px;
    border: 1px solid lightgray;
    position: relative;
    right: 14px;
  }
  
  .calendar-container {
    display: flex;
    align-items: center;
  }
  
  .icon-container {
    margin-right: 10px;
  }
  
  .calendar-wrapper {
    border-bottom: 1px solid #a9a1a1;
    max-width: 500px;
  }
  .logo{
    filter:grayscale(100%) invert(100%) brightness(2);
    width:250px;
    height:50px;
  }
  @media only screen and (max-width: 992px) {
    .car-right {
      width: 100%;
      height: auto;
      margin-top: 0;
    }
  
    .session-bg {
      background-image: none;
    }
  
    .back {
      background-image: none;
    }
  
    .flight-right {
      width: 80% !important;
    }
  
    .bannar-bg {
      background: none;
    }
  
    .form-btn {
      padding: 10px 0px 0px 0px !important;
      /* color: white; */
    }
  
    .btn-box,
    .pipe {
      color: blueviolet !important;
    }
  
    .btn-active {
      background-color: blueviolet;
      color: white !important;
    }
  }
  
  .mdi-airplane-takeoff::before {
    content: "\F05D5";
    margin-top: 10px;
  }
  
  .mdi-airplane-landing::before {
    content: "\F05D4";
    margin-top: 10px;
  }
  .footer_content{
    display:block;
  }
  @media screen and (max-width:480px) {
    .footer_content{
      display:none;
    }
  }
  @media only screen and (max-width: 1200px) {
    .owl-theme .owl-nav [class*="owl-"] {
      margin-right: 0 !important;
    }
  
    .owl-theme .item {
      width: auto;
      padding-right: 15px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .owl-theme .owl-nav [class*="owl-"] {
      margin-right: 0 !important;
    }
  
    .owl-theme .item {
      width: auto;
    }
  }
  a{
    text-decoration:underline;
  }
  .f-img {
  height: 35px;
  width: 40px;
  /* filter: contrast(0.5); */
}
  </style>
  