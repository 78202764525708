<template>
  <v-app>
    <v-main v-if="!dataloader">
      <div v-if="domaintrue" class="text-white f-size-14 d-flex justify-content-between align-center px-5 py-2" style="background-color: #005672;">
        <div>
          <span>You have arrived at the {{ portalsiteTitle }} site in</span>
          <span class="text-capitalize ms-1">{{ countryname }}</span>.
          <span>If you want to visit, click here</span>
          <span @click="routerDomain" class="border-bottom border-1 fw-500 ms-1" style="cursor: pointer">{{ userDomain }}</span>
        </div>
        <div>
          <v-icon @click="domaintrue = false" color="red" class="bg-white rounded-circle">
            mdi-close-circle-outline
          </v-icon>
        </div>
      </div>

      <div>
        <div v-if="whatsappApi && !isMobile">
          <a :href="whatsappApi" class="whatsapp-float" target="_blank" rel="noopener noreferrer">
            <v-icon class="whatsappImg" size="40">mdi-whatsapp</v-icon>
          </a>
        </div>
        <!-- <div v-if="isMobile" class="custom-chat-button" @click="toggleChatDropdown">
          <v-icon size="30">mdi-chat</v-icon>
        </div> -->
        <div id="navbar">
          <headerCom1 @localChanged="updateLocale" />
        </div>
        <!-- <HomeComponent v-if="!otherpageRender" /> -->
        <div class="bg-pages">
          <router-view />
        </div>
        <div v-if="isMobile" class="container-wrapper d-md-none d-flex flex-column" :class="{ 'expanded': isExpanded }">
          <div class="content-panel" @click="togglePanel">
            <div v-if="tawktoApi" class="social-link mb-1" :class="{ 'expanded-link': isExpanded }">
              <v-icon class="whatsapp-float1 social-icon" :class="{ 'expanded-icon': isExpanded }" color="white" @click="handleClick1(whatsappApi)">mdi-whatsapp</v-icon>
            </div>
            <div 
              v-for="(data, index) in social_media" 
              :key="index" 
              class="social-link mb-1"
              :class="{ 'expanded-link': isExpanded }"
            >
              <v-icon class="social-icon" :class="{ 'expanded-icon': isExpanded }" color="white" @click="handleClick(data)">{{ data.icon_class}}</v-icon>
            </div>
          </div>

          <!-- <div
            class="share-icon" 
            @click="togglePanel"
            :class="{ 'move-right': showPanel,'facebook-move-right': showPanel && visibleIcon === 'facebook' }"
          >
            <v-icon color="white">mdi-share-variant</v-icon>
          </div> -->
        </div>
        <div v-if="!tawktoApi">
            <a v-if="!tawktoApi" :href="whatsappApi" class="d-block whatsapp-float" target="_blank" rel="noopener noreferrer">
            <v-icon class="whatsappImg" size="40">mdi-whatsapp</v-icon>
          </a>
          </div>

        <div id="food">
          <footerCom :is="selectedFooter" />
        </div>
      </div>

    </v-main>
    <v-dialog v-model="portalError" persistent>
      <div class="d-flex justify-center">
        <v-card class="p-4">
          <h3 class="text-center">Oops! Site is being updated.</h3>
          <h5 class="text-center">Sorry for the inconvenience. We will be right back with exciting new features.</h5>
          <h6 class="text-center">Please visit again after some time.</h6>
          <div class="d-flex justify-space-evenly mt-3">
            <v-btn @click="reloadPage()" color="#162849">Retry</v-btn>
          </div>
        </v-card>
      </div>
    </v-dialog>
    <div v-if="dataloader">
      <dataloader></dataloader>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import headerCom1 from "@/components/headerCom.vue";
// import aboutUs from "@/components/aboutUs.vue";
import country1 from "@/coundtrycode.json";
import CryptoJS from "crypto-js";
import moment from "moment";

// import HomeComponent from './components/HomeComponent.vue';
import footerCom from "@/components/footerCom.vue";
import dataloader from "@/components/loaderComponent.vue"

import { setConfigDataToLocalStorage, getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  name: "App",
  components: {
    headerCom1,
    dataloader,
    footerCom,
  },
  data: () => ({
    isExpanded: false,
    dataloader:true,
    otherpageRender:false,
    portalError:false,
    showPanel: false,
    displayType:false,
    visibleIcon:null,
    togglemedia:false,
    dialog1: false,
    navbar: true,
    food: true,
    datashow: false,
    countryname: "",
    loading: true,
    visitcountryname: "",

    dataDomainData: "",
    SearchDomain: "",
    portalsiteTitle: "",
    domaintrue: false,
    userDomain:"",
    envPortalCalled: false,

    countryOptions: [],
    siteContentData: "",
    languageData: "",
    getLanguages: "",
    countryIsocode: "",

    configReq: {
      service_type: "GetPortalConfigRQ",
      api_ver: "1",
      payload: {
        domain_name: "",
        env: "CERT",
        language: "EN",
        token: "jsdjOJDKJ7675",
      },
    },

    configUser_Id: "",
      isMobile: false,
      tawktoApi: "",
    i18nlocaleLang: "",
    getEndpoint: "",
    loaderReq: {
      payload: {
        domain: "uatca.flyro.ca",
        etoken: "d9781f79c8e577d068587e0aca400e28",
      },
    },
    loaderApi: "https://otaget.nexus2.wistirna.com",
    whatsappApi: "",
    social_media:[],
    showChatOptions: false,
    tawktoWidgetVisible: false,
    settingsPanelOpen: false,
  }),

  methods: {
    reloadPage() {
      setTimeout(() => {
        location.reload();
      }, 300);
      window.location.href = '/';
      this.portalError = false;
    },
    togglePanel() {
      this.isExpanded = !this.isExpanded;
    },
     handleClick(data) {
      // this.expandedIcon = this.expandedIcon === data.type ? null : data.type;
      window.open(data.url, "_blank");
    },
    handleClick1(data){
      window.open(data, "_blank")
    },
    routerDomain(){
      localStorage.removeItem('prefDomain');
      window.location.href = `https://${this.userDomain}?ref=web&redirect=false`;
    },
    
    updateLocale(newLocale) {
      // Update the app's locale based on the event received from headerCom1.vue
      this.i18nlocaleLang = newLocale;
      console.log("App locale updated to:", this.i18nlocaleLang);
    },
    
    async getloaderPortal() {
      let hostname = window.location.hostname;
      // let domainname  = window.location.origin;

      if (hostname == "localhost") {
        console.log(hostname, "localhost....");
      } else {
        this.loaderReq.payload.domain = hostname;
      }

      let Key = "8dsxBc9vDl4I9alHbJU7Dg4EBbr7AS0w";
      let domain_name = this.loaderReq.payload.domain;
      console.log(domain_name, "domain_nameeeee");
      let current_date = moment(new Date()).format("YYYYMMDD");
      let fixed_value = 3;

      // console.log(current_date,'dasafsdfdasdasd');

      let compineskey =
        Key + domain_name + current_date + fixed_value.toString();
      const encryptedkey = CryptoJS.MD5(compineskey).toString();

      this.loaderReq.payload.etoken = encryptedkey;

      // console.log(this.loaderReq,'loderred...2');

      await axios.post(this.loaderApi, this.loaderReq, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.response_type === "success") {
            this.getEndpoint = response.data.endpoint;
            this.portalError = false;
            if(this.getEndpoint && !this.envPortalCalled){
              this.envPortal();
              this.envPortalCalled = true;
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching application:", error);
          this.dataloader = true;
          setTimeout(()=>{
            this.portalError = true;
          },3000)
        });
    },

    envPortal() {
      this.configReq.payload.domain_name = this.loaderReq.payload.domain;

      console.log(this.configReq, "configReqconfigReqconfigReq");

      axios.get(`${this.getEndpoint}getportalconfig`, {
          params: this.configReq,
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          if (response) {
            let configResponse = response.data;
            setConfigDataToLocalStorage(configResponse);

            const getConfigResponse = getConfigDataFromLocalStorage();

            if(getConfigResponse){
              this.loading = true;
              this.dataloader = false;
              this.portalError = false;
              console.log(configResponse, "configResponseconfigResponse");
              
              this.SearchDomain = this.configReq.payload.domain_name;
              this.portalsiteTitle = configResponse.payload.portal_configuration.site_title;

              this.countryname =
                configResponse.payload.portal_configuration.country.name;
              let $dataname =
                configResponse.payload.portal_configuration.geoip_location
                  .country_iso_code;

              this.getLanguages =
                configResponse.payload.portal_configuration.languages;
              this.countryOptions =
                configResponse.payload.portal_configuration.country_options;

              this.languageData =
                configResponse.payload.portal_configuration.language_data;

              this.countryIsocode =
                configResponse.payload.portal_configuration.country.iso_code;

              // Geolocation Redirection
              if(this.countryIsocode && this.countryOptions){
                //  this.geolocationRedirection();
              }
               // Geolocation Redirection End

              if (this.languageData) {
                this.getlangaugeUrl();
                // this.localeLanguage();
              } else {
                this.$i18n.locale = "CA-en";
              }


              if (this.getLanguages && this.countryIsocode) {
                this.setLanguage();
                console.log("working...1");
                // this.$i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`
                // console.log(this.$i18n.locale, "langresponseresponse...1");
              } else {
                this.$i18n.locale = "CA-en";
                console.log("working...2");
                // this.$i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`
              }

              country1.forEach((v) => {
                if ($dataname == "US" || $dataname == "CA") {
                  if (v.code == $dataname) {
                    this.visitcountryname = v.name;
                  }
                } else {
                  this.visitcountryname = "Global";
                }
              });

              if (configResponse) {
                this.datashow = true;
              }
              this.indexHeader();
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
          // this.dialog1 = true;
          // alert(
          //   "Oops! Site is being updated. Sorry, for the inconvenience. We will be right back with exciting new features. Please visit again after some time."
          // );
          this.loading = false;
          this.dataloader = true;

          setTimeout(()=>{
            this.portalError = true;
          },3000)
        });
    },


    async geolocationRedirection(){
      const configResponse = await getConfigDataFromLocalStorage();
      // Geolocation Redirection

      if(configResponse){
        let geoIsocode = null;
        let geoCountrydomain = null;
        
        if(configResponse.payload.portal_configuration.geoip_location){
          geoIsocode = configResponse.payload.portal_configuration.geoip_location.country_iso_code;
          geoCountrydomain = configResponse.payload.portal_configuration.geoip_location.country_domain;
        }

        let findDomainIso = null;
        let findIsocode = null;
        if(this.countryOptions && geoIsocode){
          findDomainIso = this.countryOptions && geoIsocode ?  (this.countryOptions.find((v) => v.country_code == geoIsocode)) || null : null;
          findIsocode = this.countryIsocode && geoIsocode ? (this.countryIsocode == geoIsocode) || null : null;
        }

        let getredirect = null;
        if (this.$route.query && this.$route.query.redirect) {
          try {
            getredirect = JSON.parse(this.$route.query.redirect);
          } catch (e) {
            console.log("Invalid redirect value:", this.$route.query.redirect);
            getredirect = null;
          }
        }
        // const getrouteRef = this.$route.query && Object.keys(this.$route.query).length > 0 ? (this.$route.query.ref || null) : null; 
        const getusercountry = this.countryIsocode ? this.countryIsocode : null;
             
        const getrouteiso = this.$route.query ? (this.$route.query.usercountry || null ) : null;
        let getrouterquery = this.$route.query && Object.keys(this.$route.query).length > 0 ? this.$route.query : null;
        
        const validKeys = ["ref", "redirect", "usercountry"];
        const excludedKeys = ["ref", "redirect"];

        const hasOnlyValidKeys = getrouterquery && Object.keys(getrouterquery).every((key) => validKeys.includes(key));
        const hasOnlyExcludedKeys = getrouterquery && Object.keys(getrouterquery).every((key) => excludedKeys.includes(key));
        const isRefOnlyWeb = getrouterquery && Object.keys(getrouterquery).length === 1 && getrouterquery.ref === "web";
        const isRefWebwithRedirect = getrouterquery && Object.keys(getrouterquery).length === 2 && getrouterquery.ref === "web" && getredirect;
        
        const isUnwantedQuery = !getrouterquery || isRefOnlyWeb || (!isRefOnlyWeb && isRefWebwithRedirect) 
         || !hasOnlyValidKeys || (hasOnlyValidKeys && !hasOnlyExcludedKeys) ||(!hasOnlyValidKeys && hasOnlyExcludedKeys);

        // Encrypt User Country 
        let secretqueryKey = "secretquery";
        let encryptedquery = null;   
        
        if(isUnwantedQuery){
          if(findDomainIso && !findIsocode){
            if(isUnwantedQuery){
              encryptedquery = CryptoJS.AES.encrypt(JSON.stringify(getusercountry),secretqueryKey).toString();
              const encodequery = encryptedquery ? btoa(encryptedquery) : null;
              let encryptiso = encodequery ? encodequery : null;
              window.location.href = `${geoCountrydomain}?ref=web&redirect=true&usercountry=${encryptiso}`;
            }
          } 
        }
        else{
          this.domaintrue = false;
        }

        // Decrypt User Country 
        
        let finduserIso = null;
        if(getrouteiso){      
          const decodedEncrypted = getrouteiso ? atob(getrouteiso) : null;
          const decrypted = CryptoJS.AES.decrypt(decodedEncrypted, secretqueryKey).toString(CryptoJS.enc.Utf8); 
          
          // Encrypt & Decrypted User Country End
          let usercountryIso = decrypted ? JSON.parse(decrypted) : null;  
          finduserIso = this.countryOptions && usercountryIso ?  (this.countryOptions.find((s) => s.country_code == usercountryIso)) || null : null;


          if(getrouteiso && getredirect && finduserIso){
            this.domaintrue = true;
            let getuserDomain = finduserIso.portal_url ? finduserIso.portal_url : null; 
  
            let trimedDomain = getuserDomain.replace("https://","");
            this.userDomain = trimedDomain.charAt(0).toUpperCase() + trimedDomain.slice(1); 
          }
        }
        else{
          this.domaintrue = false;
        }
      }
      // Geolocation Redirection End
    },


    indexHeader() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        let portalTitle = getConfigData.payload.portal_configuration.site_title;
        let portalFavicon = getConfigData.payload.portal_configuration.favicon;
        this.social_media = getConfigData.payload.portal_configuration.social_icons;

        let enabledPlugins = "";
        enabledPlugins = getConfigData.payload.portal_configuration.menus.enabled.plugins;
        // console.log(pluginWhats, 'dasdasdasda..1..1..1.1')

        if (enabledPlugins.includes("whatsapp")) {
          this.whatsappApi = getConfigData.payload.portal_configuration.API_endpoints.whatsapp;
        } else {
          this.whatsappApi = "";
        }
        // console.log(this.whatsappApi,'whatsappAPiiii')

        if (enabledPlugins.includes("tawkto")) {
          this.tawktoApi =
           getConfigData.payload.portal_configuration.API_endpoints.tawkto;
          // console.log(this.tawktoApi, " this.tawktoApithis.tawktoApi ");
        } else {
          this.tawktoApi = "";
        }

        console.log(
          this.languageData,
          "siteContentDatasiteContentDatasiteContentData"
        );

        document.getElementById("siteTitle").innerText = portalTitle;
        document.getElementById("faviconPortal").href = portalFavicon;

        // TawkTo code
        const s1 = document.createElement("script");
        const s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = this.tawktoApi;
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      }
    },

   checkIfMobile() {
      // const wasMobile = this.isMobile;
      this.isMobile = window.innerWidth <= 480; // Adjust breakpoint as needed

      // Handle position changes when switching between mobile and desktop
      // if (wasMobile !== this.isMobile) {
      //   this.updateTawkToPosition();
      // }
    },
  //   toggleChatDropdown() {
  //     this.showChatOptions = !this.showChatOptions;

  //     // Toggle Tawk.to widget visibility in mobile mode
  //     if (this.isMobile) {
  //       this.tawktoWidgetVisible = this.showChatOptions;
  //       this.toggleTawkToVisibility();
  //       this.updateTawkToPosition();
  //     }
  //   },
  //   includeTawkToScript() {
  //     // Define Tawk_API globally with customStyle
  //     window.Tawk_API = window.Tawk_API || {};
  //     window.Tawk_API.onLoad = () => this.updateTawkToPosition();

  //     // Dynamically include the Tawk.to script
  //     const tawkScript = document.createElement("script");
  //     tawkScript.src = this.tawktoApi; // Replace with your Tawk.to property ID
  //     tawkScript.async = true;
  //     tawkScript.charset = "UTF-8";
  //     tawkScript.setAttribute("crossorigin", "*");
  //     document.body.appendChild(tawkScript);
  //   },
    // updateTawkToPosition() {
    //   if (window.Tawk_API) {
    //     // Define custom styles for desktop and mobile
    //     window.Tawk_API.customStyle = {
    //       visibility: {
           
    //         mobile: {
    //           position: "cr",
    //           xOffset: "10px", // Horizontal offset for mobile
    //           yOffset: 190,     // Vertical offset for mobile
    //         },
    //          desktop: {
    //           position: "br",
    //           xOffset: "30px", // Horizontal offset for desktop
    //           yOffset: 20,     // Vertical offset for desktop
    //         }
    //       },
    //     };
    //      if (this.isMobile) {
    //       window.Tawk_API.customStyle.visibility = {
    //         position: "cr",
    //         xOffset: "10px",
    //         yOffset: 190,
    //       };
    //     } else {
    //       window.Tawk_API.customStyle.visibility = {
    //         position: "br",
    //         xOffset: "30px",
    //         yOffset: 20,
    //       };
    //     }

    //     // Ensure the position updates are applied
    //     window.Tawk_API.customStyles = window.Tawk_API.customStyle;
    //      setTimeout(() => {
    //       window.Tawk_API.customStyles = window.Tawk_API.customStyle;
    //     }, 200);
    //   }
    //   console.log(window.Tawk_API.customStyles,'isMobile...1')
    //   console.log(window.Tawk_API,'isMobile...1')
    // },
    //  updateTawkToPosition() {
    // if (window.Tawk_API) {
    //   // Ensure the widget container gets the custom styles with !important to override default
    //   const widget = document.querySelector(".tawk-chat-container");
    //   if (widget) {
    //     widget.style.position = "absolute !important";
    //     widget.style.right = this.isMobile ? "10px !important" : "30px !important";
    //     widget.style.bottom = this.isMobile ? "190px !important" : "20px !important";
    //   }

    //   // Optionally, log for debugging
    //   console.log("Custom position applied: ", {
    //     position: this.isMobile ? "cr" : "br",
    //     right: this.isMobile ? "10px" : "30px",
    //     bottom: this.isMobile ? "190px" : "20px",
    //   });
    // }
    // },
    // toggleTawkToVisibility() {
    //   // Show or hide the Tawk.to widget dynamically
    //   if (window.Tawk_API) {
    //     if (this.tawktoWidgetVisible) {
    //       window.Tawk_API.showWidget();
    //       this.updateTawkToPosition();
    //     } else {
    //       window.Tawk_API.hideWidget();
    //       // try{
    //       // }
    //       // catch(e){
    //       //   console.log("hideWidget not working!");
    //       // }
    //     }
    //   }
    // },


    setLanguage() {
      let getLang = this.getLanguages[0].code;
      let savedLanguage = `${this.countryIsocode}-${getLang}`;
      localStorage.setItem("setlang", savedLanguage);
      // let setlocalLang = localStorage.setItem("setlang", savedLanguage);
      // if(setlocalLang){
      //   this.localeLanguage();
      // }
    },
    localeLanguage() {
      const storedLocale = localStorage.getItem("defaultlang");
      const storedSetLang = localStorage.getItem("setlang");

      let getlocalIso = "";
      let getlocalLang = "";
      let getsetIso = "";
      let getsetlang = "";
      // let savedLanguage = "";

      if (storedLocale) {
        getlocalIso = storedLocale.split("-")[0];
        getlocalLang = storedLocale.split("-")[1];
      }
      if (storedSetLang) {
        getsetIso = storedSetLang.split("-")[0];
        getsetlang = storedSetLang.split("-")[1];
      }

      if (storedSetLang && storedLocale) {
        console.log("Check...1");
        if (storedLocale == storedSetLang) {
          console.log("Check...2");

          return storedSetLang;
          // this.i18n.locale = storedSetLang;
        } else if (storedLocale !== storedSetLang) {
          console.log(
            getlocalIso,
            getsetIso,
            "Check...3",
            getlocalLang,
            getsetlang
          );
          if (getlocalIso !== getsetIso && getlocalLang !== getsetlang) {
            localStorage.removeItem("defaultlang");
            console.log("Check...4");
            return storedSetLang;
            // this.i18n.locale = storedSetLang;
          } else if (getlocalIso == getsetIso && getlocalLang !== getsetlang) {
            console.log(storedLocale, "Check...5");

            return storedLocale;
            // this.i18n.locale = storedLocale;
          }
        }
      } else if (storedSetLang) {
        console.log("Check...6");

        return storedSetLang;
        // this.i18n.locale = storedSetLang;
      } else if (storedLocale) {
        console.log("Check...7");

        return storedLocale;
        // this.i18n.locale = storedLocale;
      } else {
        console.log("Check...8");

        return "CA-en";
        // return `${this.countryIsocode}-${this.getLanguages[0].code}`;

        // this.i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`;
      }
    },

    getlangaugeUrl() {
      let langData = this.languageData;
      // console.log(langData, 'languageDatalanguageDatalanguageData...1');
      // let getlocale = this.localeLanguage();
      // this.$i18n.locale = this.localeLanguage();
      var localLang = this.localeLanguage(); //CA.es

      // console.log(localLang, 'languageDatalanguageDatalanguageData...2');

      let langdataUrl = "";
      // console.log(localLang,'localLanglocalLang')
      Object.keys(langData).forEach((v) => {
        console.log(
          v,
          this.countryIsocode,
          "languageDatalanguageDatalanguageData...3"
        );
        if (v == this.countryIsocode) {
          const lang = langData[v];

          Object.keys(lang).forEach((local) => {
            // console.log(local, 'dasasasasdas')
            if (local == localLang) {
              langdataUrl = lang[local];
              console.log(local, localLang, langdataUrl, "dasasasasdas...2");

              axios
                .get(langdataUrl, {
                  headers: {
                    "Content-Type": "text/html; charset=utf-8",
                  },
                })
                .then((response) => {
                  if (response) {
                    // this.aboutContent = response.data
                    // console.log(response, "langresponseresponse...2");
                    // console.log(response.data, "langresponseresponse");
                    const cleanedData = this.parseLanguageData(response.data);
                    this.setLanguageMessages(localLang, cleanedData);
                    // setInterval(() => {
                    //   location.reload();
                    // }, 500);
                    this.dataloader = false;
                  }
                  // else {
                  //   this.$i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`
                  //   console.log(this.$i18n.locale, "langresponseresponse...1");
                  // }
                })
                .catch((error) => {
                  console.log(error, "errorrLangaugeAPI.....");

                  this.$i18n.locale = "CA-en";
                  this.dataloader = true;
                });
            }
          });
        }
      });
    },

    parseLanguageData(data) {
      try {
        const cleanedData = data
          .replace(/export\s+default\s+/, "")
          .replace(/;\s*$/, ""); // Remove trailing semicolon if present
        return eval(`(${cleanedData})`);
      } catch (error) {
        console.error("Error processing language content:", error);
        return {};
      }
    },

    setLanguageMessages(locale, messages) {
      this.$i18n.setLocaleMessage(locale, messages);
      this.$i18n.locale = locale;

      console.log(this.$i18n.locale, "setLanguageMessagessetLanguageMessages");
    },
    handleOutsideClick(event) {
      if(!event.target.closest(".container-wrapper")){
        this.isExpanded = false;
      }
    }
  },
  mounted() {
    this.getloaderPortal();
    // this.includeTawkToScript();
    // this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
    document.body.addEventListener("click", this.handleOutsideClick);
    this.isMobile = window.innerWidth <= 480;
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkIfMobile);
    document.body.removeEventListener("click", this.handleOutsideClick);
  },

  // created() {
  //   this.envPortal();
  //   // this.getRandomChars();

  //   setTimeout(() => {
  //     // this.indexHeader();
  //   }, 2000);
  // },

  watch: {
    isMobile(newVal) {
      console.log(newVal,'isMobile')
      //  this.updateTawkToPosition();

      // Close chat options and hide widget when switching to mobile
      if (!newVal) {
        this.showChatOptions = false;
        this.tawktoWidgetVisible = true; // Always visible on desktop
        // this.updateTawkToPosition();
      } else {
        this.tawktoWidgetVisible = false; // Initially hidden on mobile
        // this.toggleTawkToVisibility();
      }
    },
    showChatOptions(newVal){
      console.log(newVal)
      this.updateTawkToPosition();
    },
    i18nlocaleLang: {
      immediate: true,
      handler: function () {
        // this.localeLanguage();
        this.getlangaugeUrl();
      },
      // deep: true,
    },
    "$route.path": {
      immediate: true,
      handler(newValue) {
        this.getloaderPortal();
        // this.domaintrue = newValue === "/" ? true : false;
        // this.otherpageRender = newValue !== "/" ? true : false;
        if (newValue === "/loader") {
          this.navbar = false;
          this.food = false;
        } else {
          this.navbar = true;
          this.food = true;
        }
        // console.log(newValue, '4545');
      },
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&family=Poppins:wght@300;400;500&display=swap");

/* .bg-pages {
background: rgb(230, 217, 26);
background: linear-gradient(to right, rgb(45 129 253 / 65%), rgb(193 193 193));
} */

/* * {
  font-family: 'Poppins', sans-serif;
} */

* {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

#navbar {
  display: block;
}

#food {
  display: block;
}

.bg-pages {
  background: #f1f1f1;
  /* background-image:url('./assets/bmbg2.svg'); */
}
.whatsapp-float1{
  display:none;
}
.whatsapp-float {
  position: fixed;
  width: 55px;
  height: 52px;
  bottom: 115px;
  right: 30px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  box-shadow: 1px 1px 2px #888;
}
v-icon:hover {
  color: white;
}
.whatsapp-float:hover {
  color: white;
}
a:visited {
  color: white !important;
}
/* .whatsapp-float img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.whatsappImg {
  width: 45px;
  height: 45px;
}
.whatsappImg:hover {
  color: white;
}
.v-overlay-container .v-overlay__content {
  min-width: 0 !important;
}
/* .bg-pages{
    background: linear-gradient(90deg, rgba(148, 187, 233, 0.7512254901960784) 0%, rgba(238, 174, 202, 1) 50%, rgba(148, 187, 233, 0.7456232492997199) 100%);
  } */
/* .bg-pages { */

/* } */

/* footer floating icons */

.floating-icons {
  position: fixed;
  top: 78%;
  left: 10px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.floating-icons a {
  text-decoration: none;
  border-radius: 50%;
  background-color: #26225e; 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  color: #fff;
  transition: background-color 0.3s, transform 0.3s;
}

.floating-icons a:hover {
  background-color: #26225e;
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .floating-icons {
    display: flex;
  }
  .chat-float{
    display:block;
  }
}

@media (min-width: 769px) {
  .floating-icons {
    display: none;
  }
  .chat-float{
    display:none;
  }
}
@media (max-width:480px){
  .whatsapp-float{
    display:block;
    right:20px;
    bottom:18px;
  }
  .whatsapp-float1{
    display:flex;
  }
}

/* Floating Chat Button */
.custom-chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #005672;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.chat-options {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat-option {
  background-color: #005672;
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.whatsapp-float {
  background-color: #25d366;
}

.tawkto-float {
  background-color: #162849;
}
/* Wrapper for positioning */
.container-wrapper {
  position: fixed;
  /* top: 75%; */
  bottom:-1%;
  left: 3px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  z-index: 0;
  transition: all 0.3s ease-in-out;
}

/* Content Panel */
.content-panel {
  display: flex;
  flex-direction: column;
  /* gap: 2px; */
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px 13px 10px 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  position: fixed;
  /* top: 60px; */
  bottom:3px;
  left:3px;
}

/* Social Media Links */
.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #26225e;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-decoration: none;
  /* transition: background-color 0.3s ease-in-out; */
  transition: all 0.3s ease-in-out;
}
.container-wrapper.expanded{
  bottom:-1%;
}
.social-link.expanded-link{
  width: 35px;
  height: 35px;
}
.social-icon{
  position:fixed;
  font-size:12px !important;
  width:13px;
  height:13px;
  transition: all 0.3s ease-in-out;
}
.social-icon.expanded-icon{
  font-size:20px !important;
}
.social-name{
  position: absolute;
  left: 37px;
  background-color: #26225e;
  padding: 4px 5px;
  font-size: 20px;
  border-radius: 5px;
}

.icon-name {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.social-link:hover .icon-name {
  display: block;
  opacity: 1;
  transform: translateX(-50%) translateY(5px); /* Adjust positioning as needed */
}
/* .social-link:hover {
  background-color: #007bff;
} */

/* Share Icon */
.share-icon {
  width: 45px;
  height: 45px;
  background-color: #26225e;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

/* Move Share Icon Right */
.share-icon.move-right {
  transform: translateX(25%);
}
.share-icon.facebook-move-right {
  transform: translateX(105px);
  transition: transform 0.3s ease;
}
/* 
:deep(.widget-visible){
  position: relative !important;
  bottom: 60px !important;
} */


</style>
