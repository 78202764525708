import { createRouter, createWebHistory } from "vue-router";
import HomeComponent from "../components/HomeComponent.vue";
import SearchResult from "../components/SearchResult.vue";
import FlightInfo from "../components/flightInfo.vue";
import login from "../components/loginCom.vue";
import register from "../components/registerCom.vue";
import loader from "../components/loaderComponent.vue";
import ConfirmBook from "../components/confirmBook.vue";
import BookingView from "../components/bookingView.vue";
import aboutUs from "../components/aboutUs.vue";
import contactPage from "../components/contactPage.vue";
import privacyPolicy from "../components/privacyPolicy.vue";
import termUse from "../components/termUse.vue";
import myBooking from "../components/myBooking.vue";
import pageError from "../components/pageError.vue";
import pageNot from "../components/pageNot.vue";
import newPassword from "@/components/newPassword.vue";
import myProfile from "../components/myProfile.vue";
import testCom from "../components/testCom.vue";
import viewBooking from "../components/viewBooking.vue";
import seatMapping from "../components/seatMapping.vue";

const routes = [
  {
    path: "/",
    component: HomeComponent,
  },
  // {
  //   path: "/test",
  //   component: test,
  // },
  {
    path: "/flight/search",
    component: SearchResult,
  },
  {
    path: "/flightInfo",
    component: FlightInfo,
  },
  {
    path: "/login",
    component: login,
  },
  {
    path: "/register",
    component: register,
  },
  {
    path: "/confirmBook",
    component: ConfirmBook,
  },
  {
    path: "/bookingView",
    component: BookingView,
  },
  {
    path: "/loader",
    component: loader,
  },
  {
    path: "/aboutUs",
    component: aboutUs,
  },
  {
    path: "/contactPage",
    component: contactPage,
  },
  {
    path: "/page/privacy-policy",
    component: privacyPolicy,
  },
  {
    path: "/page/terms-of-use",
    component: termUse,
  },
  {
    path: "/myBooking",
    component: myBooking,
  },
  {
    path: "/pageError",
    component: pageError,
  },
  {
    path: "/pageNot",
    component: pageNot,
  },
  {
    path: "/newPassword",
    component: newPassword,
  },
  {
    path: "/myProfile",
    component: myProfile,
  },
  {
    path:"/test",
    component:testCom
  },
  {
    path:"/viewBooking",
    component:viewBooking
  },
  {
    path:"/seatmap",
    component:seatMapping
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
