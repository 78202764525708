import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "YOUR_MICROSOFT_CLIENT_ID", // Replace with your App ID
    authority: "https://login.microsoftonline.com/common", // Authority URL
    redirectUri: "http://localhost:8080", // Your redirect URI
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

let initialized = false;

// Ensure MSAL is initialized before login
export const microsoftAuth = {
  async initialize() {
    if (!initialized) {
      try {
        await msalInstance.initialize(); // Ensure MSAL is ready
        initialized = true;
      } catch (error) {
        console.error("MSAL Initialization Error:", error);
      }
    }
  },

  async login() {
    if (!initialized) {
      await this.initialize();
    }
    try {
      const loginResponse = await msalInstance.loginPopup({
        scopes: ["openid", "profile", "email"], // Define the scopes
      });
      return loginResponse;
    } catch (error) {
      console.error("Microsoft Login Error:", error);
    }
  },
};
