<template>
  <div class="p-3" style="background-color:#ffffffd9">
    <section v-html="aboutContent"></section>
  </div>
</template>

<script>
import axios from "axios";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  data() {
    return {
      aboutContent: "",
      getAboutUrl: "",
      aboutType: "",
      getLangname: "",
      preventData: false,
    };
  },

  methods: {
    async getConfig() {
      const locale = this.getLangname;
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        const siteContentData = getConfigData.payload.portal_configuration.site_content_data;

        if (siteContentData[locale] && siteContentData[locale].aboutus) {
          this.getAboutUrl = siteContentData[locale].aboutus.url;
          this.aboutType = siteContentData[locale].aboutus.type;

        //   console.log(`Using language: ${locale}`, "About URL:", this.getAboutUrl);

          await this.getConfigFunc();
        } else {
          console.error(`No aboutus data found for locale: ${locale}`);
        }
      }
    },

    async getConfigFunc() {
      try {
        const response = await axios.get(this.getAboutUrl, {
          headers: {
            "Content-Type": this.aboutType,
          },
        });

        this.aboutContent = response.data;
        // console.log("Fetched about content:", response.data);
      } catch (error) {
        console.error("Error fetching about content:", error);
      }
    },

    updateContent() {
      const currentLocale = this.$i18n.locale;

      const routeLang = this.$route.query.lang || currentLocale;

      if (this.getLangname !== routeLang) {
        this.getLangname = routeLang;
        console.log("Updated language:", this.getLangname);
        this.getConfig();
      }
    },
  },

  watch: {
    "$i18n.locale": {
      handler() {
        this.updateContent();
      },
    },
  },

  mounted() {
    this.updateContent();
  },
};
</script>
<style scoped>
@media screen and (max-width:998px) {
  :deep(.footr-content-wrap p){
    text-align:justify;
  }
}
</style>