<template>
  <div class="social-container">
    <!-- Share Icon -->
    <div @click="toggleIcons" class="share-icon">
      <i class="fas fa-share-alt"></i> <!-- Share icon -->
    </div>

    <!-- Social Icons Wrapper -->
    <div :class="['social-icons', { show: showSocialIcons }]">
      <!-- Loop through each social icon -->
      <div
        v-for="icon in socialIcons"
        :key="icon.name"
        class="social-icon"
        @click="toggleText(icon.name)"
      >
        <i :class="icon.iconClass">
          <span v-if="visibleIcon === icon.name" class="icon-text">{{ icon.displayName }}</span>
        </i>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      socialIcons: [
        { name: "Facebook", iconClass: "fab fa-facebook-f", displayName: "FACEBOOK" },
        { name: "Twitter", iconClass: "fab fa-twitter", displayName: "TWITTER" },
        { name: "Instagram", iconClass: "fab fa-instagram", displayName: "INSTAGRAM" },
        { name: "LinkedIn", iconClass: "fab fa-linkedin", displayName: "LINKEDIN" },
        { name: "GitHub", iconClass: "fab fa-github", displayName: "GITHUB" },
        { name: "YouTube", iconClass: "fab fa-youtube", displayName: "YOUTUBE" },
      ],
      showSocialIcons: false, // To toggle visibility of social icons
      visibleIcon: null, // Track which icon's text is visible
    };
  },
  methods: {
    // Toggle visibility of social icons when share icon is clicked
    toggleIcons() {
      this.showSocialIcons = !this.showSocialIcons;

      // Reset the visible icon when hiding social icons
      if (!this.showSocialIcons) {
        this.visibleIcon = null;
      }
    },

    // Toggle the visibility of the icon's name on click
    toggleText(iconName) {
      // If the same icon is clicked again, hide its name
      this.visibleIcon = this.visibleIcon === iconName ? null : iconName;
    },
  },
};
</script>

<style scoped>
.social-container {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  transition: left 0.3s ease; /* Smooth transition when toggling icons */
}

.share-icon {
  background-color: #333;
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  border-radius: 0 5px 5px 0; /* Rounded corner on the right */
  transition: transform 0.3s ease;
}

.social-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px; /* Distance between share icon and social icons */
  opacity: 0;
  transition: opacity 0.3s ease; /* Fade-in effect for social icons */
}

.social-icons.show {
  opacity: 1;
}

.social-icon {
  position: relative;
  background: #555;
  color: white;
  width: 50px;
  height: 50px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease; /* Smooth icon appearance */
}

.icon-text {
  position: absolute;
  left: 100%; /* Position text to the right of the icon */
  background-color: #e4405f;
  color: white;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 14px;
  opacity: 0; /* Initially hidden */
  transform: translateX(20px); /* Text slides in */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth text transition */
}

.social-icon:hover .icon-text {
  opacity: 1; /* Show text on hover */
  transform: translateX(0); /* Slide text into place */
}

.facebook { background-color: #3b5998; }
.twitter { background-color: #55acee; }
.instagram { background-color: #e4405f; }
.linkedin { background-color: #0077b5; }
.github { background-color: #333; }
.youtube { background-color: #cd201f; }

</style>

