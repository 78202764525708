import { createApp } from 'vue'
import App from './App.vue'
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import PrimeVue from 'primevue/config';
import router from "./router/route.js";
import i18n from './plugins/i18n'; 
// import vue3GoogleLogin from 'vue3-google-login';
// import AppleAuth from './plugins/appleAuth';
// import FacebookAuth from './plugins/facebookAuth';

loadFonts();

createApp(App)
.use(router)
.use(vuetify)
.use(PrimeVue)
.use(i18n)
// .use(vue3GoogleLogin, {
//     clientId: 'YOUR_GOOGLE_CLIENT_ID'
// })
// .use(AppleAuth, {
//     clientId: 'com.yourapp.identifier',
//     redirectUri: 'https://yourapp.com/callback',
// })
// .use(FacebookAuth, {
//     clientId: 'your-facebook-app-id',
//     redirectUri: 'https://yourapp.com/callback',
//   })
.mount('#app')
